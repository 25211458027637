<template>
  <q-card flat bordered :class="{ 'card-true': valid === true, 'card-false': valid === false }">
    <q-card-section class="row items-baseline q-pb-none">
      <h6 class="q-my-none q-mr-sm">Question n°{{ index }} |</h6>
      <div style="font-size: 1.1rem;" v-html="data.question"></div>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <q-list v-if="data.type === 'true-false'">
        <q-item tag="label" :disable="valid" v-ripple v-for="opt in [
          { label: 'True', value: true, color: controlsColor },
          { label: 'False', value: false, color: controlsColor },
        ]" :key="opt.value">
          <q-item-section avatar>
            <q-radio v-model="answer" :val="opt.value" :disable="valid" :color="controlsColor" />
          </q-item-section>
          <q-item-section>
            <q-item-label :class="['text-body1', opt.expected ? 'text-weight-medium' : '']" v-html="opt.label"></q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <q-list v-else-if="data.type === 'single-choice'">
        <q-item tag="label" :disable="valid" v-ripple v-for="opt in options" :key="opt.value">
          <q-item-section avatar>
            <q-radio v-model="answer" :val="opt.value" :disable="valid" :color="controlsColor" />
          </q-item-section>
          <q-item-section>
            <q-item-label :class="['text-body1', opt.expected ? 'text-weight-medium' : '']" v-html="opt.label"></q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <q-list v-else-if="data.type === 'multiple-choice'">
        <!--
          Rendering a <label> tag (notice tag="label")
          so QCheckboxes will respond to clicks on QItems to
          change Toggle state.
        -->
        <q-item tag="label" :disable="valid" v-ripple v-for="opt in options" :key="opt.value">
          <q-item-section avatar>
            <q-checkbox v-model="answer" :val="opt.value" :disable="valid" :color="controlsColor" />
          </q-item-section>
          <q-item-section>
            <q-item-label :class="['text-body1', opt.expected ? 'text-weight-medium' : '']" v-html="opt.label"></q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <QuizMatchQuestion v-else-if="data.type === 'match'"
        ref="question"
        v-model="answer"
        :entries="data.entries"
        :outputs="data.outputs"
        :is-valid="isValid"
        :color="controlsColor || 'primary'"
      ></QuizMatchQuestion>
      <QuizFillWordsQuestion v-else-if="data.type === 'fill-words'"
        ref="question"
        :index="index"
        :data="data"
        :is-valid="valid"
      ></QuizFillWordsQuestion>

      <q-banner v-if="valid !== null" rounded :class="['q-mt-md', `bg-${controlsColor}-1`]">
        <span style="font-size: 0.95rem;" v-html="data.details"></span>
      </q-banner>
    </q-card-section>

  </q-card>
</template>

<script>
import isEqual from 'lodash.isequal';
import QuizFillWordsQuestion from './QuizFillWordsQuestion.vue';
import QuizMatchQuestion from './QuizMatchQuestion.vue';

export default {
  name: 'QuizQuestion',
  components: { QuizFillWordsQuestion, QuizMatchQuestion },
  props: {
    data: { type: Object, required: true },
    index: { type: Number, required: true },
    isValid: { type: [null, Boolean], default: null },
  },
  data() {
    return {
      answer: null,
      expected: null,
      valid: this.isValid,
    };
  },
  created() {
    if (this.data.type === 'multiple-choice') this.answer = [];
    if (this.data.type === 'match') this.answer = {};
  },
  computed: {
    controlsColor() {
      if (this.valid) return 'green';
      if (this.valid === false) return 'red';
      return null;
    },
    options() {
      if (this.data.type === 'multiple-choice') {
        return this.data.options.map((o) => {
          const data = { ...o, color: this.controlsColor };
          if (this.valid === false && this.expected.includes(o.value))
            data.expected = true;
          return data;
        });
      } else if (this.data.type === 'single-choice') {
        return this.data.options.map((o) => ({ ...o, color: this.controlsColor }));
      }
      return [];
    },
  },
  methods: {
    reset(finished, expected) {
      this.valid = finished ? true : null;
      if (!finished) {
        if (this.data.type === 'multiple-choice') this.answer = [];
        if (this.data.type === 'match') this.answer = {};
      } else {
        this.expected = expected;
        this.answer = expected;
        if (this.data.type === 'match') this.$refs.question.setAnswer(expected);
      }
    },
    checkIsValid(expected) {
      if (
        this.data.type !== 'fill-words' && (
        this.answer === null ||
        (Array.isArray(this.answer) && this.answer.length === 0) ||
        (typeof this.answer === 'object' && Object.keys(this.answer).length === 0)
      )) return null;

      this.expected = expected;

      if (this.data.type === 'true-false' || this.data.type === 'single-choice') {
        return this.answer === expected;
      }

      if (this.data.type === 'multiple-choice' || this.data.type === 'match') {
        return isEqual(this.answer, expected);
      }

      if (this.data.type === 'fill-words') {
        return this.$refs.question.checkIsValid();
      }
    },
    setSvgOffset() {
      this.$refs.question.setSvgOffset();
    },
  },
}
</script>

<style lang="sass" scoped>
.card-true
  border-color: $green-8
  color: $green-7
.card-false
  border-color: $red-8
  color: $red-7

.question-title
  display: grid
  grid-template-columns: 96px auto 1fr
  align-items: baseline
  column-gap: 0.5rem

.question-title:deep(.q-avatar)
  margin-top: -8px

:deep(.q-item)
  padding-left: 0

:deep(.q-item__section--avatar)
  min-width: auto

:deep(.q-item__section--side)
  padding-right: 8px

:deep(.q-item.disabled)
  opacity: 1 !important
</style>
