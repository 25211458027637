import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';

const state = () => ({
  posts: [],
  reviews: [],
  tags: [],
});

const getters = {
  getPostBySlug: (state) => (slug) => {
    return state.posts.find((p) => p.slug === slug);
  },
  getPostByRef: (state) => (ref) => {
    const idx = state.posts.findIndex((p) => p.url === ref);
    return {
      post: state.posts[idx],
      previous: idx > 0 ? state.posts[idx - 1].url : null,
      next: idx < state.posts.length - 1 ? state.posts[idx + 1].url : null,
    };
  },
};

const mutations = {
  setPosts(state, { posts }) {
    const tags = [];
    for (const p of posts) {
      tags.push(...p.tags);
    }

    state.posts = posts;
    state.tags = [ ...new Set(tags) ];
  },
  setReviews(state, { reviews }) {
    state.reviews = reviews;
  },
  setPostData(state, { ref, postData }) {
    const posts = cloneDeep(state.posts);
    const idx = state.posts.findIndex((p) => p.url === ref);
    posts[idx] = { ...posts[idx], ...postData };
    state.posts = posts;
  },
};

const actions = {
  async getPosts({ commit }) {
    const { data } = await axios.get('/posts');
    commit('setPosts', { posts: data.posts });
    commit('setReviews', { reviews: data.reviews });
    return data;
  },
  async getPostData({ commit }, { ref }) {
    const { data: postData } = await axios.get(`/posts/${ref}`);
    commit('setPostData', { ref, postData });
    return postData;
  },
  async toggleReview({ commit }, { slug, name }) {
    const { data: reviews } = await axios.post(`/posts/${slug}/review`, { name });
    commit('setReviews', { reviews });
    return reviews;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
