<template>
  <div class="text-body1">
    <div v-if="intro" ref="intro" v-html="intro"></div>
    <pre v-if="content" ref="content" :class="`language-${data.lang}`" tabindex="0">
      <code :class="`language-${data.lang}`" v-html="content.html"></code>
    </pre>
  </div>
</template>

<script>
import { colors } from 'quasar';
import { highlightCode, parseAndHighlightWordsFill } from '@/helpers/html.js';

export default {
  name: 'QuizFillWordsQuestion',
  props: {
    index: { type: Number, required: true },
    data: { type: Object, required: true },
    isValid: { type: [null, Boolean], default: null },
  },
  data() {
    return {
      content: null,
      intro: '',
      ready: false,
    };
  },
  computed: {
    baseColors() {
      return {
          border: 'transparent',
          highlight: colors.getPaletteColor('orange-1'),
          text: colors.getPaletteColor('grey-9'),
        };
    },
    validColors() {
      return {
        border: colors.getPaletteColor('green-5'),
        highlight: colors.getPaletteColor('green-1'),
        text: colors.getPaletteColor('green-7'),
      };
    },
    invalidColors() {
      return {
        border: colors.getPaletteColor('red-5'),
        highlight: colors.getPaletteColor('red-1'),
        text: colors.getPaletteColor('red-7'),
      };
    },
  },
  created() {
    this.setup();
  },
  updated() {
    if (!this.content) return;

    // replace fake tags with actual inputs
    // (do it only once, when the DOM is ready)
    if (!this.ready) {
      if (this.$refs.intro) {
        highlightCode(this.$refs.intro);
      }

      const tags = this.$refs.content.querySelectorAll('span.token');
      let input, inputEl, inputIndex = 0;
      for (const tag of tags) {
        if (tag.innerText === '<faketag') {
          input = this.content.inputs[inputIndex];
          inputEl = this.replaceFakeTagByInput(input, tag);
          input.element = inputEl;
          input.content = '';
          if (!input.extra || input.extra !== 'closing-tag')
            inputIndex++;
        }
        else if (tag.innerText === '</faketag>') {
          input = this.content.inputs[inputIndex];
          inputEl = this.replaceFakeTagByInput(input, tag.querySelector('span.token.tag'));
          input.closingElement = inputEl;
          inputIndex++;
        }
        else if (tag.innerText.includes('faketag')) {
          // only replace if it's a leaf of the DOM tree
          const realChildren = [...tag.childNodes].filter((n) => n.nodeName !== '#text');
          if (realChildren.length === 0) {
            input = this.content.inputs[inputIndex];
            inputEl = this.replaceFakeTagByInput(input, tag);
            input.element = inputEl;
            input.content = '';
            inputIndex++;
          }
        }
      }

      if (this.isValid !== null) {
        this.updateValidInputs();
      }

      this.ready = true;
    }
  },
  watch: {
    isValid(newValid, oldValid) {
      if (newValid !== oldValid && this.content?.inputs) {
        this.updateValidInputs();
      }
    },
  },
  methods: {
    async setup() {
      const { slug, part } = this.$route.params;
      const sectionIndex = parseInt(part.substr(0, 2));
      const content = await this.$store.dispatch('projects/getProjectTutorialQuestion', {
        slug, sectionIndex, questionIndex: this.index - 1 });
      let main = content;
      if (Array.isArray(content)) {
        main = content[0];
        this.intro = content[1];
      }
      this.content = parseAndHighlightWordsFill(main, this.data.lang);
    },
    replaceFakeTagByInput(input, tag) {
      // 1. clean up visual to remove the "faketag" tag name
      // (only keep first "real child")
      const realChildren = [...tag.childNodes].filter((n) => n.nodeName !== '#text');
      if (realChildren.length > 0) {
        const n = tag.childNodes[0];
        tag.innerHTML = '';
        tag.append(n);
      } else {
        tag.innerHTML = '';
      }
      
      // 2. create and append new input element
      const inputEl = document.createElement('input');
      const width = {
        small: 80,
        large: 240,
      }[input.size];
      inputEl.style.width = `${width}px`;
      inputEl.oninput = () => {
        input.content = inputEl.value;
        // if need be, update the matching tag (in case of 'closing-tag' extra)
        if (input.extra === 'closing-tag') {
          if (inputEl === input.element) input.closingElement.value = input.content;
          else input.element.value = input.content;
        }
      }
      tag.append(inputEl);
      return inputEl;
    },
    checkIsValid() {
      let empty = true;
      for (const input of this.content.inputs) {
        if (input.content !== '') {
          empty = false;
          if (input.content !== input.answer) {
            return false;
          }
        }
      }
      return empty ? null : true;
    },
    updateValidInputs() {
      for (const input of this.content.inputs) {
        if (this.isValid) {
          input.content = input.answer;
          input.element.value = input.answer;
          input.element.setAttribute('readonly', true);
        }
        const className = (input.content && input.content === input.answer) ? 'valid' : 'invalid';
        input.element.className = className;
        if (input.closingElement) {
          input.closingElement.className = className;
          if (this.isValid) {
            input.closingElement.value = input.answer;
            input.closingElement.setAttribute('readonly', true);
          }
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
pre:deep(input)
  --border-color: v-bind('baseColors.border')
  --highlight-color: v-bind('baseColors.highlight')
  --text-color: v-bind('baseColors.text')
pre:deep(input.valid)
  --border-color: v-bind('validColors.border')
  --highlight-color: v-bind('validColors.highlight')
  --text-color: v-bind('validColors.text')
pre:deep(input.invalid)
  --border-color: v-bind('invalidColors.border')
  --highlight-color: v-bind('invalidColors.highlight')
  --text-color: v-bind('invalidColors.text')

:deep(input)
  outline: none
  border: 1px solid var(--border-color)
  background-color: white
  border-radius: 2px
  font-family: monospace
  color: var(--text-color)
:deep(input:focus)
  background-color: var(--highlight-color)
</style>
