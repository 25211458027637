<template>
  <ContentView
    ref="content"
    item-type="projects"
    :items="projects"
    :reviews="reviews"
    :tags="tags"
    item-grid-class="fit row wrap justify-center items-stretch content-start q-col-gutter-lg"
    item-read-state-class="text-center"
    item-wrapper-class="col-lg-3 col-md-4 col-sm-6 grid-card"
    search-placeholder="Find a project..."
    :fetch-items-fn="fetchItemsFn"
    :extra-filter-fn="extraFilterFn"
    :query-compute-fn="queryComputeFn"
    :query-parse-fn="queryParseFn"
    has-filters
    large-container
  >
    <template #filters-panel>
      <q-item class="bg-primary text-white text-uppercase text-weight-bold">
        <q-item-section>category</q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <q-option-group
            v-model="filterTypes"
            :options="filterTypeOptions"
            @update:model-value="$refs.content.updateURL"
            color="primary"
            type="checkbox"
            class="q-mb-sm"
            dense
          />
        </q-item-section>
      </q-item>
      <q-item class="bg-primary text-white text-uppercase text-weight-bold">
        <q-item-section>state</q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <q-option-group
            v-model="filterStates"
            :options="filterStateOptions"
            @update:model-value="$refs.content.updateURL"
            color="primary"
            type="checkbox"
            class="q-mb-sm"
            dense
          />
        </q-item-section>
      </q-item>
    </template>

    <!-- <template #before-content>
      <div class="row justify-center items-center q-my-lg">
        <q-btn
          style="background-color: #f96855; color: white; text-transform: uppercase"
          size="lg"
          @click="connectToPatreon"
        >
          <img :src="$options.getRelativeImg('/patreon.png')" style="width: auto; height: 32px;" class="q-mr-sm">
          Log in with Patreon
        </q-btn>
      </div>
    </template> -->

    <template #special-filters>
      <div class="gt-sm row q-pa-lg q-mt-md justify-around">
        <HighlightBubble
          v-for="bubble in typeHighlightBubbles"
          :key="bubble.id"
          :data="bubble"
          title-component="h6"
          size="60px"
          font-size="32px"
          :active="bubble.active"
          is-clickable
          @click-bubble="() => {
            filterTypes = bubble.id === 'all' ? [ ...types ] : [bubble.id];
            $refs.content.updateURL();
          }"                
        ></HighlightBubble>
      </div>
    </template>

    <template v-slot="slug">
      <ProjectCard style="height: 100%;" :slug="slug.data"></ProjectCard>
    </template>
  </ContentView>
</template>

<script>
import { mapState } from 'vuex';
import { getRelativeImg } from '@/helpers/html.js';
import { TYPE_ICONS, TYPE_LABELS } from '@/helpers/type';
import { STATE_LABELS } from '@/helpers/state';

import ContentView from '@/views/ContentView.vue';
import HighlightBubble from '@/components/layout/HighlightBubble.vue';
import ProjectCard from '@/components/project/ProjectCard.vue';

export default {
  getRelativeImg,
  name: 'Library',
  components: {
    ContentView,

    HighlightBubble,
    ProjectCard
  },
  data() {
    return {
      filterTypes: [],
      filterStates: [],
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects.projects,
      reviews: (state) => state.projects.reviews,
      tags: (state) => state.projects.tags,
    }),
    types() { return Object.keys(TYPE_LABELS).sort(); },
    states() { return ['done', 'wip', 'todo']; },
    filterTypeOptions() {
      return this.types.map((t) => ({
        label: TYPE_LABELS[t],
        value: t,
      }));
    },
    filterStateOptions() {
      return this.states.map((s) => ({
        label: STATE_LABELS[s],
        value: s,
      }));
    },
    typeHighlightBubbles() {
      if (!this.$refs.content) return [];
      const {
        bookmarkedOnly,
        bookmarkedItems,
        filteredItemSlugs
      } = this.$refs.content;

      const counts = this.countsPerType;
      let data = bookmarkedOnly ? bookmarkedItems : this.projects;
      if (this.filterStates.length > 0)
        data = data.filter((item) => this.filterStates.includes(item.state));
      return [
        {
          id: 'all',
          title: `All (${data.length})`,
          icon: 'apps',
          active: filteredItemSlugs.length === this.projects.length,
        },
        ...this.types
        .filter((k) => counts[k] > 0)
        .map((k) => ({
          id: k,
          title: `${TYPE_LABELS[k]} (${counts[k]})`,
          icon: TYPE_ICONS[k],
          active: this.filterTypes.includes(k)
        }))
      ];
    },
    countsPerType() {
      if (!this.$refs.content) return [];
      const {
        bookmarkedOnly,
        bookmarkedItems
      } = this.$refs.content;

      return this.types.reduce((acc, k) => {
        let data = this.projects;
        if (bookmarkedOnly)
          data = data.filter((item) => bookmarkedItems.includes(item.slug));
        if (this.filterStates.length > 0)
          data = data.filter((item) => this.filterStates.includes(item.state));
        return { ...acc, [k]: data.filter((p) => p.type === k).length };
      }, {});
    },
  },
  methods: {
    async fetchItemsFn() {
      if (this.projects.length === 0)
        await this.$store.dispatch('projects/getProjects');
    },
    extraFilterFn(data) {
      if (this.filterTypes.length > 0)
        data = data.filter((item) => this.filterTypes.includes(item.type));

      if (this.filterStates.length > 0)
        data = data.filter((item) => this.filterStates.includes(item.state));
      
      return data;
    },
    queryComputeFn() {
      const query = [];
      if (this.filterTypes.length !== this.filterTypeOptions.length)
        query.push(`t=${this.filterTypes.join(',')}`);
      if (this.filterStates.length !== this.filterStateOptions.length)
        query.push(`s=${this.filterStates.join(',')}`);
      return query;
    },
    queryParseFn(query) {
      if ('t' in query) {
        this.filterTypes = query.t.split(',');
      } else {
        this.filterTypes = [ ...this.types ];
      }

      if ('s' in query) {
        this.filterStates = query.s.split(',');
      } else {
        this.filterStates = [ ...this.states ];
      }
    },
    async connectToPatreon() {
      const user = await this.$store.dispatch('patreon/connect');
      console.log('>', user)
    },
  },
}
</script>
