<template>
  <OptionalRouterLink :disabled="!data.to" :to="data.to" style="text-decoration: none; color: black;">
    <q-card :class="['notif all-pointer-events shadow-16', (data.to || data.dismissOnClick) ? 'cursor-pointer' : '']" @click="onClick">
      <q-card-section class="notif-row">
        <div v-if="data.icon" :ratio="1" class="notif-icon">
          <q-icon :name="data.icon" color="white" size="30px"></q-icon>
        </div>
        <div>
          <div class="notif-message">{{ data.message }}</div>
          <div v-if="data.caption" class="notif-caption">{{ data.caption }}</div>
        </div>
      </q-card-section>
    </q-card>
  </OptionalRouterLink>
</template>

<script>
import { markRaw } from 'vue';
import { colors } from 'quasar';

import OptionalRouterLink from '@/components/extra/OptionalRouterLink.vue';

export default markRaw({
  name: 'NotificationCard',
  components: { OptionalRouterLink },
  props: {
    data: { type: Object, required: true },
  },
  computed: {
    color() {
      return colors.getPaletteColor(this.data.color);
    },
  },
  methods: {
    onClick() {
      if (this.data.dismissOnClick) this.$emit('dismiss');
    },
  },
})
</script>

<style lang="sass" scoped>
.notif
  border: 3px solid v-bind('color')

.notif-row
  display: flex
  align-items: center
  gap: 1rem

.notif-icon
  background-color: v-bind('color')
  width: 45px
  height: 45px
  display: grid
  place-items: center
  border-radius: 4px

.notif-message
  font-size: 1.25rem
  line-height: 1.1
  color: $grey-9

.notif-caption
  font-size: 1.1rem
  color: $grey-7
</style>
