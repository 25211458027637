<template>
  <div v-if="passwordStrength" class="password-strength-meter">
    <div v-for="i in passwordStrength.value" :key="i" class="bar col-3"
      :style="{ 'background-color': passwordStrength.color }"></div>
    <div v-for="i in 4 - passwordStrength.value" :key="i" class="bar empty col-3"></div>
  </div>
</template>

<script>
import { colors } from 'quasar';

export default {
  name: 'PasswordStrengthMeter',
  props: {
    password: { type: String, required: true },
  },
  computed: {
    passwordStrength() {
      // adapted from: https://www.youtube.com/watch?v=qQP3WBSn2yU

      if (this.password === '') return 0;
      let score = 0;

      let letterCounts = {};
      for (let i = 0; i < this.password.length; i++) {
        letterCounts[this.password[i]] = (letterCounts[this.password[i]] || 0) + 1;
        score += 5.0 / letterCounts[this.password[i]];
      }

      const variations = [
        /\d/.test(this.password),      // digits
        /[a-z]/.test(this.password),   // lowercase
        /[A-Z]/.test(this.password),   // uppercase
        /\W/.test(this.password),      // special
      ];
      let nVariations = 0;
      for (const variation of variations) {
        nVariations += (variation === true) ? 1 : 0;
      }
      score += (nVariations - 1) * 10;
      score = parseInt(score);

      if (score === 0) return null;

      const negColor = colors.getPaletteColor('negative');
      const posColor = colors.getPaletteColor('positive');
      if (score < 25) return { value: 1, color: negColor };
      if (score < 50) return { value: 2, color: '#f1a801' };
      if (score < 75) return { value: 3, color: '#a3bd0b' };
      return { value: 4, color: posColor };
    },
  },
}
</script>

<style lang="sass" scoped>
.password-strength-meter
  display: flex
  height: 4px
  justify-content: space-between
  margin-top: 0.5rem
  margin-bottom: 1.5rem

.password-strength-meter .bar
  flex-basis: 23%
  height: 100%
  border-radius: 2px

.password-strength-meter .bar.empty
  background-color: $grey-4
</style>
