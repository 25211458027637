<template>
  <div class="notifications">
    <div :class="['notifications-wrapper', newAbove ? 'reversed' : '']">
      <transition-group
        name="custom-classes"
        :enter-active-class="`animate__animated animate__${enterClass}`"
        :leave-active-class="`animate__animated animate__${leaveClass}`"
      >
        <component
          v-for="notif in notifications"
          :key="notif.id"
          :is="notif.component"
          :data="notif"
          @dismiss="dismiss(notif.id)"
          @dismiss-all="dismissAll"
        ></component>
      </transition-group>
    </div>
  </div>
</template>

<script>
import NotificationCard from './NotificationCard.vue';

export default {
  name: 'Notifications',
  props: {
    enterClass: { type: String, default: 'fadeIn' },
    leaveClass: { type: String, default: 'fadeOut' },
    newAbove: { type: Boolean, default: true },
  },
  data() {
    return {
      lastId: 0,
      notifications: [],
      defaults: {
        timeout: 3000,
        component: NotificationCard,
        dismissOnClick: true,
        color: 'primary',
      },
    };
  },
  created() {
    this.$lib.notif = this;
  },
  watch: {
    '$route.name': {
      handler: function() {
        this.dismissAll();
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    setDefaults(defaults) {
      this.defaults = defaults;
    },
    add(data) {
      // get data and merge with defaults
      let { message, ...options } = data;
      options = { ...this.defaults, ...options };

      // extract timeout
      const timeout = options.timeout;
      delete options.timeout;

      // SPECIAL: check incompatible options:
      // . 'to' + 'dismissOnClick' > 'to' takes priority
      if (options.to && options.dismissOnClick)
        options.dismissOnClick = false;

      // add notification
      const id = this.lastId++;
      const notif = {
        id,
        message,
        ...options,
      };
      // add timeout destroy (if timeout = 0, then infinite notif)
      if (timeout > 0) {
        notif.timeoutFn = window.setTimeout((() => { this.dismiss(id); }).bind(this), timeout);
      } else {
        notif.timeoutFn = null;
      }

      this.notifications.push(notif);
    },
    dismiss(id) {
      const idx = this.notifications.findIndex((n) => n.id === id);
      const notif = this.notifications[idx];
      if (notif.timeoutFn) {
        window.clearTimeout(notif.timeoutFn);
        notif.timeoutFn = null;
      }

      this.notifications.splice(idx, 1);
    },
    dismissAll() {
      const notifs = [ ...this.notifications ];
      for (const n of notifs)
        this.dismiss(n.id);
    }
  },
}
</script>

<style lang="sass" scoped>
.notifications
  position: fixed
  z-index: 10001
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  display: grid
  place-items: center
  pointer-events: none

.notifications-wrapper
  display: flex
  gap: 1rem
.notifications-wrapper:not(.reversed)
  flex-direction: column
.notifications-wrapper.reversed
  flex-direction: column-reverse
</style>
