<template>
  <q-page-container>
    <q-page style="min-height: auto;">
      <img :src="$options.getRelativeImg('404.png')" style="max-width: 100vw;" />
    </q-page>
  </q-page-container>
</template>

<script>
import { getRelativeImg } from '@/helpers/html';

export default  {
  getRelativeImg,
  name: '404',
}
</script>
