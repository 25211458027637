<template>
  <svg viewBox="0 0 20 16" :width="size" :height="size" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M19.6877602,1.89293557 C18.9636059,2.21427222 18.1858086,2.43150288 17.3680266,2.52914459 C18.202647,2.02936289 18.842036,1.23738588 19.1439277,0.294801869 C18.3511063,0.765691951 17.4834345,1.0971983 16.5786041,1.27492034 C15.842251,0.490033168 14.7922246,0 13.6305332,0 C11.4007259,0 9.59234714,1.80764895 9.59234714,4.03875954 C9.59234714,4.35488306 9.62795274,4.66329117 9.69640099,4.95939632 C6.33972592,4.7906998 3.36381705,3.18281756 1.37188435,0.739637297 C1.02406506,1.33633088 0.824976052,2.03009273 0.824976052,2.77030347 C0.824976052,4.17132915 1.53807857,5.40746388 2.62162532,6.13156609 C1.98018359,6.11168915 1.35281934,5.93855042 0.79197701,5.6266234 L0.79197701,5.67765983 C0.79197701,7.63466463 2.18393186,9.26647508 4.0316697,9.63759701 C3.69224353,9.73059905 3.33603112,9.77918532 2.96751575,9.77918532 C2.70696408,9.77918532 2.45417994,9.75452727 2.20723451,9.70807838 C2.72119589,11.3121029 4.21277344,12.4796851 5.97960367,12.5126842 C4.59734522,13.5962309 2.85564222,14.2421363 0.9639578,14.2421363 C0.638137874,14.2421363 0.316123525,14.2226914 0,14.1845835 C1.78763057,15.3308441 3.9101519,16 6.19104777,16 C13.6195336,16 17.6822213,9.84580898 17.6822213,4.50882646 C17.6822213,4.33361354 17.6783636,4.15970389 17.6699183,3.98641983 C18.4614607,3.41389378 19.1447382,2.70500237 19.6877602,1.89293557 Z"></path>
  </svg>
</template>

<script>
export default {
  name: 'IconTwitter',
  props: {
    color: { type: String, default: '#ffffff' },
    size: { type: Number, default: 24 },
  }
}
</script>
