<template>
  <div id="project" style="{ overflow-y: isAccessible ? 'initial' : 'hidden'; max-height: 100vh; }">
    <template v-if="!loading">
      <q-page-container>
        <q-page id="page" class="relative" :style="pageStyle">
          <template v-if="data.meta">
            <q-scroll-observer @scroll="onScroll"></q-scroll-observer>
            <!-- medium/large screens -->
            <template v-if="$q.screen.gt.sm">
              <SideFloatingPanel id="side-panel" style="top: 500px; min-width: 160px;">
                <q-btn flat icon="chevron_left" label="back to list" color="grey-9"
                  @click="backToList" class="q-pl-none"></q-btn>
  
                <q-separator class="q-my-md"></q-separator>
  
                <template v-if="hasShop">
                  <!-- one shop link: direct href -->
                  <q-btn
                    v-if="data.meta.shop.length === 1"
                    :href=" data.meta.shop[0]"
                    :disable="!isAccessible"
                    target="_blank"
                    push
                    color="primary"
                    class="q-mt-md q-px-xs"
                    style="width: 100%;"
                  >
                    {{ data.type === 'game' ? 'Play' : 'Get' }}
                  </q-btn>
                  <!-- multiple shop links: show dialog -->
                  <q-btn
                    v-else
                    @click="showShopDialog = true"
                    :disable="!isAccessible"
                    push
                    color="primary"
                    class="q-mt-md q-px-xs"
                    style="width: 100%;"
                  >
                    {{ data.type === 'game' ? 'Play' : 'Get' }}
                  </q-btn>
                </template>
                <q-btn
                  v-if="hasDownload"
                  :href="data.meta.download"
                  :disable="!isAccessible"
                  target="_blank"
                  push
                  color="primary"
                  class="q-mt-md q-px-md"
                  style="width: 100%;"
                >
                  <q-icon v-if="!downloadIcon.startsWith('/imgs')" :name="downloadIcon" class="q-mr-sm"></q-icon>
                  <img v-else :src="downloadIcon" style="height: 32px; width: auto;" class="q-mr-sm" />
                  {{ data.meta.download_text || 'download' }}
                </q-btn>
                <q-btn
                  v-if="data.meta.github"
                  :href="data.meta.github" target="_blank"
                  :disable="!isAccessible"
                  push
                  :color="(hasShop || hasDownload) ? 'secondary' : 'primary'"
                  :class="`q-mt-sm q-px-xs ${((hasShop || hasDownload) ? 'text-grey-10' : 'text-white')}`"
                  style="width: 100%;"
                >
                  <IconGithub :size="20" :color="(hasShop || hasDownload) ? '#222222' : 'white'" class="q-mr-sm"></IconGithub>
                  {{ (hasShop || hasDownload) ? 'code' : 'get' }}
                </q-btn>
                <q-btn
                  v-if="data.meta.docs"
                  :href="data.meta.docs" target="_blank"
                  :disable="!isAccessible"
                  push
                  color="secondary"
                  class="q-mt-sm q-px-md text-grey-10"
                  style="width: 100%;"
                >
                  <q-icon name="description" class="q-mr-sm"></q-icon>
                  Docs
                </q-btn>
              </SideFloatingPanel>

              <q-dialog v-model="showShopDialog">
                <q-card class="q-pa-md" style="width: 50vw; max-width: 400px;">
                  <q-card-section class="row">
                    <div class="text-h6">Pick a shop!</div>
                    <q-space />
                    <q-btn icon="close" flat round dense v-close-popup />
                  </q-card-section>

                  <q-card-section class="q-pt-none" style="width: 80%; max-width: 300px; margin: 1rem auto 0 auto;">
                    <q-btn
                      v-for="shop in data.meta.shop"
                      :key="shop.name"
                      :href="shop.url"
                      target="_blank"
                      push
                      color="primary"
                      class="q-my-sm"
                      style="display: block;"
                    >
                      {{ shop.name }}
                    </q-btn>
                  </q-card-section>
                </q-card>
              </q-dialog>
            </template>
            <!-- small screens -->
            <template v-else>
              <div ref="bottom-bar" class="bottom-bar">
                <q-btn flat icon="chevron_left" label="back" color="grey-9"
                  @click="backToList" class="back-button q-pl-none" size="16px"></q-btn>
                <ItemReviews
                  :slug="data.slug"
                  item-type="projects"
                  :disable="!isAccessible"
                  tooltip-position="right"
                  size="lg"
                ></ItemReviews>
                <q-btn flat round icon="more_horiz" color="grey-9" @click="showDetails = true" size="lg"></q-btn>
              </div>
              <q-dialog v-model="showDetails">
                <q-card>
                  <q-card-section class="column q-pa-xl" style="min-width: 80vw;">
                    <div class="text-h5 text-center q-mb-md">Curious?</div>
                    <p class="text-body1 q-mb-lg">
                      Wanna learn more about this project, or get it for yourself? Check out the links below! :)
                    </p>
                    <template v-if="hasShop">
                      <!-- one shop link: direct href -->
                      <q-btn
                        v-if="data.meta.shop.length === 1"
                        :href=" data.meta.shop[0]"
                        :disable="!isAccessible"
                        target="_blank"
                        push
                        color="primary"
                        style="width: 100%;"
                      >
                        {{ data.type === 'game' ? 'Play' : 'Get' }}
                      </q-btn>
                      <!-- multiple shop links: show dialog -->
                      <template v-else>
                        <q-btn
                          v-for="shop in data.meta.shop"
                          :key="shop.name"
                          :href="shop.url"
                          target="_blank"
                          push
                          color="primary"
                          class="q-my-sm"
                          style="display: block;"
                        >
                          {{ shop.name }}
                        </q-btn>
                      </template>
                    </template>

                    <template v-if="hasDownload">
                      <q-btn
                        :href="data.meta.download"
                        :disable="!isAccessible"
                        target="_blank"
                        push
                        color="primary"
                        class="q-mt-md q-px-md"
                        style="width: 100%;"
                      >
                        <q-icon :name="data.meta.download_icon || 'download'" class="q-mr-sm"></q-icon>
                        {{ data.meta.download_text || 'download' }}
                      </q-btn>
                    </template>
                    <template v-if="data.meta.github">
                      <q-btn
                        :href="data.meta.github" target="_blank"
                        :disable="!isAccessible"
                        push
                        :color="(hasShop || hasDownload) ? 'secondary' : 'primary'"
                        :class="`q-mt-sm q-px-xs ${((hasShop || hasDownload) ? 'text-grey-10' : 'text-white')}`"
                        style="width: 100%;"
                      >
                        <IconGithub :size="20" :color="(hasShop || hasDownload) ? '#222222' : 'white'" class="q-mr-sm"></IconGithub>
                        {{ (hasShop || hasDownload) ? 'code' : 'get' }}
                      </q-btn>
                    </template>
                    <template v-if="data.meta.docs">
                      <q-btn
                        :href="data.meta.docs" target="_blank"
                        :disable="!isAccessible"
                        push
                        color="secondary"
                        class="q-mt-sm q-px-md text-grey-10"
                        style="width: 100%;"
                      >
                        <q-icon name="description" class="q-mr-sm"></q-icon>
                        Docs
                      </q-btn>
                    </template>
                  </q-card-section>
                </q-card>
              </q-dialog>
            </template>
          </template>

          <q-parallax
            :src="$options.getRelativeImg(data.banner || `/trianglify-${data.type}.png`)"
            class="parallax-cover"
            :height="300"
            :speed="0.8"
          ></q-parallax>
    
          <div class="main-container inner-container relative">
            <div class="column q-mb-md items-center q-px-md">
              <div
                :class="[$q.screen.gt.md ? 'text-h2' : 'text-h3', 'q-mt-xl q-mb-sm text-center']"
                style="max-width: 90vw;"
              >{{ data.name }}</div>
              <h5 class="q-my-md text-center text-grey-9">{{ data.date }}<span v-if="tools" class="q-ml-sm">{{ tools }}</span></h5>
              <div class="row">
                <ProjectTagState :state="data.state"></ProjectTagState>
                <ProjectTagType :type="data.type"></ProjectTagType>
              </div>
            </div>

            <template v-if="data.meta">
              <template v-if="data.meta.pitches">
                <!-- medium/large screens -->
                <div v-if="$q.screen.gt.sm" class="row q-pa-lg q-mt-lg justify-around">
                  <HighlightBubble
                    v-for="(pitch, index) in data.meta.pitches"
                    :key="index"
                    :data="pitch"
                    raw-html
                    title-component="h4"
                    class-name="col-3"
                  ></HighlightBubble>
                </div>
                <!-- small screens -->
                <div v-else class="q-mt-xl">
                  <HighlightBubble
                    v-for="(pitch, index) in data.meta.pitches"
                    :key="index"
                    :data="pitch"
                    raw-html
                    title-component="h5"
                    horizontal
                    :class="[index < data.meta.pitches.length - 1 ? 'q-mb-lg' : '']"
                  ></HighlightBubble>
                </div>
              </template>

              <div :class="['q-pt-xl text-body1', $q.screen.gt.sm ? 'q-px-xl' : 'q-px-sm']" v-html="data.content[0].content"></div>

              <template v-if="data.meta.imgs">
                <q-carousel
                  v-model="slide"
                  transition-prev="jump-right"
                  transition-next="jump-left"
                  swipeable
                  animated
                  prev-icon="arrow_back_ios"
                  next-icon="arrow_forward_ios"
                  padding
                  arrows
                  navigation
                  height="66vw"
                  style="max-height: 600px;"
                  class="bg-grey-10 q-my-xl text-white shadow-1 rounded-borders"
                >
                  <q-carousel-slide
                    v-for="(img, index) in data.meta.imgs"
                    :key="index"
                    :name="index"
                    :img-src="$options.getRelativeImg(img.src)"
                    class="column no-wrap flex-center"
                  >
                    <div v-if="img.desc" class="absolute-bottom custom-caption">
                      <div :class="[$q.screen.gt.sm ? 'text-h4' : ($q.screen.gt.xs ? 'text-h5' : 'text-h6')]">{{ img.desc }}</div>
                    </div>
                  </q-carousel-slide>
                </q-carousel>
              </template>

              <MusicPlaylist
                v-if="data.meta.music_playlist"
                :data="data.meta.music_playlist">
              </MusicPlaylist>

              <template v-if="data.type === 'tutorial' && data.meta.sections?.length > 0">
                <TutorialTOC :project-slug="data.slug" :data="data.meta.sections"></TutorialTOC>
              </template>

              <q-tabs
                v-model="tab"
                class="tabs-sticky text-primary text-body1"
                outside-arrows
                mobile-arrows
              >
                <q-tab v-if="isBook" name="toc" label="Table of contents"></q-tab>
                <q-tab
                  v-for="section in data.content.slice(1)"
                  :key="section.title"
                  :name="$options.toSlug(section.title)"
                  :label="section.title"
                ></q-tab>
              </q-tabs>

              <q-tab-panels id="tabs" v-model="tab" animated @transition="onTabChange">
                <q-tab-panel v-if="isBook" name="toc">
                  <q-timeline color="primary" v-for="(part, partIndex) in data.meta.toc" :key="partIndex">
                    <q-timeline-entry
                      :title="`${part.name.toUpperCase()}`"
                      :subtitle="data.meta.toc.length > 1 ? `Part ${partIndex + 1}` : 'Table of contents'"
                      icon="circle"
                    ></q-timeline-entry>

                    <q-timeline-entry
                      v-for="(chapter, chapterIndex) in part.items"
                      :key="chapterIndex"
                      :title="chapter.name"
                    >
                      <div class="text-body1" v-html="chapter.desc"></div>
                    </q-timeline-entry>
                  </q-timeline>

                </q-tab-panel>
                <q-tab-panel
                  v-for="section in data.content.slice(1)"
                  :key="section.title"
                  :name="$options.toSlug(section.title)"
                  v-html="section.content"
                  class="text-body1">
                </q-tab-panel>

              </q-tab-panels>
            </template>

          </div>
          <Footer></Footer>
        </q-page>
      </q-page-container>

      <OverlayLockPanel
        v-if="!isAccessible"
        :requires-patreon="requiresPatreon"
        class-label="text-h4 q-my-md"
        class-title="text-h2"
      ></OverlayLockPanel>
    </template>
    <template v-else>
      <q-inner-loading :showing="loading">
        <q-spinner-hourglass
          color="primary"
          size="4em"
          class=""
        />
      </q-inner-loading>
    </template>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import slugify from 'slugify';
import { mapState } from 'vuex';
import { routerData } from '@/router/index.js';
import { parseHtmlSections, fitContainerWithSidePanel } from '@/helpers/html';
import { getRelativeImg, highlightCode } from '@/helpers/html.js';

import Footer from '@/components/layout/Footer.vue';
import IconGithub from '@/components/icons/IconGithub.vue';
import MusicPlaylist from '@/components/extra/MusicPlaylist.vue';
import HighlightBubble from '@/components/layout/HighlightBubble.vue';
import OverlayLockPanel from '@/components/layout/OverlayLockPanel.vue';
import SideFloatingPanel from '@/components/layout/SideFloatingPanel.vue';
import ProjectTagState from '@/components/project/ProjectTagState.vue';
import ProjectTagType from '@/components/project/ProjectTagType.vue';

import TutorialTOC from '@/components/tutorial/TutorialTOC.vue';

const toSlug = (name) => {
  return slugify(name).replaceAll('"', '');
};

export default {
  toSlug,
  getRelativeImg,
  name: 'Project',
  components: {
    Footer,
    IconGithub,
    MusicPlaylist,
    HighlightBubble,
    OverlayLockPanel,
    SideFloatingPanel,

    ProjectTagState,
    ProjectTagType,

    TutorialTOC
  },
  data() {
    return {
      loading: true,
      data: null,
      showShopDialog: false,
      showDetails: false,
      slide: 0,
      tab: null,
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects.projects,
      user: (state) => state.patreon.user,
    }),
    isAccessible() {
      if (!this.projects) return false;
      return this.$store.getters['projects/isProjectAccessible'](this.data.slug);
    },
    tools() {
      if (this.data.tools && this.data.tools.length > 0)
        return `— ${this.data.tools.join(', ')}`;
      return null;
    },
    isBook() {
      return this.data.type === 'book';
    },
    hasShop() {
      if (!this.data || !this.data.meta) return false;
      return 'shop' in this.data.meta;
    },
    hasDownload() {
      if (!this.data || !this.data.meta) return false;
      return 'download' in this.data.meta;
    },
    downloadIcon() {
      const { download_icon } = this.data.meta;
      if (download_icon) {
        const specialIcon = {
          blender: '/imgs/blender-logo_white.png',
          unity: '/imgs/unity-logo_white.png',
        }[download_icon];
        return specialIcon || download_icon;
      }
      return 'download';
    },
    pageStyle() {
      return {
        'max-height': '100vh',
        'overflow-y': this.isAccessible ? 'initial' : 'hidden',
      };
    }
  },
  async created() {
    if (this.projects.length === 0)
      await this.$store.dispatch('projects/getProjects');

    const $this = this;
    window.setTimeout(async () => {
      try {
        const { slug } = $this.$route.params;
        $this.data = $this.$store.getters['projects/getProjectBySlug'](slug);
        if (!$this.data) {
          this.$router.push({ name: '404' });
        } else if (!('content' in $this.data)) {
          $this.data = {
            ...$this.data,
            ...(await $this.$store.dispatch('projects/getProjectData', { slug }))
          };
        }
  
        const d = cloneDeep($this.data);
        d.content = parseHtmlSections(d.content);
        if (!this.isAccessible)
          d.content = d.content.slice(0, 1);
        $this.data = d;
  
        if (this.isAccessible) {
          // check for specific tab, or use first one as default
          const { hash } = window.location;
          if (hash) this.tab = hash.substring(1);
          else {
            if (this.data.type === 'book') this.tab = 'toc';
            else {
              const firstSection = this.data.content.find((section) => section.title.length > 0);
              if (firstSection) {
                this.tab = toSlug(firstSection.title);
              }
            }
          }
        } else {
          $this.$store.commit('global/setPageScroll', { scrollable: false });
        }
  
        $this.loading = false;
      } catch {
        $this.$router.push({ name: '404' });
      }
    }, 200);
  },
  updated() {
    highlightCode();
    fitContainerWithSidePanel();
  },
  methods: {
    onScroll(scrollInfo) {
      // on mobiles
      const bottomBar = this.$refs['bottom-bar'];
      if (bottomBar) {
        if (scrollInfo.position.top < 10) bottomBar.classList.remove('visible');
        else bottomBar.classList.add('visible');
      }
    },
    backToList() {
      const { lastListPath } = routerData;
      if (lastListPath) this.$router.push(lastListPath);
      else this.$router.push({ name: 'library' });
    },
    onTabChange(newHash) {
      const { history } = this.$router.options;
      history.replace(`${this.$route.path}#${newHash}`);

      const tabs = document.getElementById('tabs');
      highlightCode(tabs);

      // scroll to top of tabs with offset
      window.scrollTo({ top: tabs.offsetTop - 96, behavior: 'smooth' });
    },
  },
}
</script>

<style lang="sass" scoped>
.parallax-cover img
  background-attachment: fixed
  background-position: center
  background-repeat: no-repeat
  background-size: cover

.custom-caption
  text-align: center
  padding: 12px
  color: white
  background-color: rgba(0, 0, 0, .3)

.main-container:deep(.q-carousel__navigation--bottom)
  bottom: 72px

.main-container:deep(.q-carousel__navigation .q-btn .q-icon)
  border: 1px solid $grey-5
  border-radius: 50%

.main-container:deep(.q-tab__label)
  font-size: 1rem


.tabs-sticky
  position: sticky
  top: 48px
  z-index: 9
  background-color: white
  padding-top: 12px

.q-tab-panel:deep(h6)
  margin-top: 28px
  margin-bottom: 14px

.q-tab-panel:deep(table)
  border-collapse: collapse
  margin: 1rem auto

.q-tab-panel:deep(table td),
.q-tab-panel:deep(table th)
  border: 1px solid currentColor
  padding: 0.35rem 0.65rem

:deep(.q-tabs--horizontal .q-tabs__arrow--left),
:deep(.q-tabs--horizontal .q-tabs__arrow--right)
  top: 20px
</style>
