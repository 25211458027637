<template>
  <q-chip
    square
    :color="data.color"
    :icon="data.icon"
    :class="`non-selectable ${elemClass}`"
    :style="style"
  >
    {{ data.name }}
  </q-chip>
</template>

<script>
import { STATE_COLORS, STATE_ICONS, STATE_LABELS } from '@/helpers/state';

export default {
  name: 'ProjectTagState',
  props: {
    state: { type: String, required: true },
    elemClass: { type: String, default: '' },
    style: { type: Object, default: () => {} },
  },
  computed: {
    data() {
      const color = (this.state in STATE_COLORS) ? `${STATE_COLORS[this.state]}-3` : 'transparent';
      const icon = (this.state in STATE_ICONS) ? STATE_ICONS[this.state] : null;
      const name = (this.state in STATE_LABELS) ? STATE_LABELS[this.state] : '';
      return { color, icon, name };
    },
  },
}
</script>
