<template>
  <q-card v-if="data" class="cross-page-music">
    <router-link :to="{ path: data.ref }" style="height: 60px;">
      <img class="shadow-4" :src="$options.getRelativeImg(data.data[currentTrack].picture || '/music-cover-placeholder.png')" />
    </router-link>
    <q-btn
      icon="skip_previous"
      @click="playPrevious"
      :disable="currentTrack === 0"
      round
      flat
      color="primary"
      size="14px"
    ></q-btn>
    <q-btn
      :icon="isPlaying ? 'pause' : 'play_arrow'"
      @click="togglePlay"
      round
      color="primary"
      size="14px"
    ></q-btn>
    <q-btn
      icon="skip_next"
      @click="playNext"
      :disable="currentTrack === data.data.length - 1"
      round
      flat
      color="primary"
      size="14px"
    ></q-btn>
    <q-btn
      icon="close"
      @click="close"
      round
      outline
      color="primary"
      size="14px"
    ></q-btn>
  </q-card>
</template>

<script>
import { mapState } from 'vuex';
import { getRelativeImg } from '@/helpers/html.js';
import { delay } from '@/helpers/misc.js';

export default {
  getRelativeImg,
  name: 'CrossPageMusic',
  data() {
    return {
      audio: null,
      currentTrack: 0,
      isPlaying: false,
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.global.crossPageMusic,
    }),
  },
  methods: {
    close() {
      if (this.audio)
        this.audio.pause();
      this.$store.commit('global/setCrossPageMusic', null);
    },
    playPrevious() {
      this.playTrack(this.currentTrack - 1);
    },
    playNext() {
      this.playTrack(this.currentTrack + 1);
    },
    async playTrack(index) {
      await delay(150);

      if (this.audio)
        this.audio.pause();

      this.currentTrack = index;

      const $this = this;
      await new Promise((resolve) => {
				const audioFile = $this.data.data[$this.currentTrack].mp3;
				$this.audio = new Audio(audioFile);
				resolve();
			});
      this.$store.commit('global/updateCrossPageMusicAudio', { audio: this.audio });
			this.audio.onended = this.onEnded;
      this.audio.play();
    },
    togglePlay() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) this.audio.play();
      else this.audio.pause();
    },
    onEnded() {
      if (this.currentTrack === this.data.data.length - 1) {
        this.isPlaying = false;
        return;
      }

      this.playNext();
    },
  },
  created() {
    if (this.data !== null) {
      this.audio = this.data.currentAudio;
      this.audio.onended = this.onEnded;
    }
    this.currentTrack = 0;
    this.isPlaying = true;
  },
  watch: {
    data(newData) {
      if (newData !== null) {
        this.audio = this.data.currentAudio;
        this.audio.onended = this.onEnded;
      }
      else if (this.audio) {
        this.audio.pause();
      }
    }
  },
  beforeUnmount() {
    this.close();
  },
}
</script>

<style lang="sass" scoped>
.cross-page-music
  position: fixed
  bottom: 8px
  right: 8px
  column-gap: 0.5rem
  padding: 8px
  display: flex
  justify-content: center
  align-items: center
  z-index: 999

.cross-page-music img
  width: 60px
  height: 60px
  border-radius: 8px !important
</style>
