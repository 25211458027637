export const TYPE_COLORS = {
    'game': 'orange',
    'tutorial': 'blue',
    'book': 'cyan',
    '2d-3d': 'red',
    'tool': 'blue-grey-2',
    'audio': 'deep-purple',
};

export const TYPE_ICONS = {
    'game': 'sports_esports',
    'tutorial': 'school',
    'book': 'auto_stories',
    '2d-3d': 'category',
    'tool': 'settings',
    'audio': 'graphic_eq',
};

export const TYPE_LABELS = {
    'game': 'Games',
    'tutorial': 'Tutorials',
    'book': 'Books',
    '2d-3d': '2D/3D',
    'tool': 'Tools',
    'audio': 'Audio',
};
