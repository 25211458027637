
import './styles/quasar.sass';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';

export default {
  config: {
    screen: {
      bodyClasses: true
    },
  },
};
