<template>
  <div id="tutorial" style="{ overflow-y: isAccessible ? 'initial' : 'hidden'; max-height: 100vh; }">
    <template v-if="!loading">
      <q-page-container>
        <q-page id="page" class="relative" :style="pageStyle">
          <template v-if="data">
            <!-- medium/large screens -->
            <SideFloatingPanel id="side-panel" v-if="$q.screen.gt.sm" style="top: 150px; width: 350px; max-height: calc(100vh - 200px); overflow: hidden;">
              <TutorialTOC
                :data="project.meta.sections"
                :project-slug="project.slug"
                mode="sidebar"
                :page-titles="pageTitles"
              ></TutorialTOC>
            </SideFloatingPanel>
            <!-- small screens -->
            <template v-else>
              <div ref="bottom-bar" :class="['bottom-bar extendable', showTOC ? 'extended' : 'visible']">
                <!-- default visual (condensed at bottom) -->
                <template v-if="!showTOC">
                  <q-space></q-space>
                  <q-btn flat round icon="list" color="grey-9" @click="toggleBottomBar(true)"></q-btn>
                  <q-space></q-space>
                </template>
                <!-- extended visual (= full-height TOC) -->
                <div v-else>
                  <div class="row">
                    <q-space></q-space>
                    <q-btn flat round icon="expand_more" color="grey-9" @click="toggleBottomBar(false)"></q-btn>
                    <q-space></q-space>
                  </div>
                  <TutorialTOC
                    :data="project.meta.sections"
                    :project-slug="project.slug"
                    mode="sidebar"
                    :page-titles="pageTitles"
                    :max-height="$q.screen.height - 152"
                  ></TutorialTOC>
                </div>
              </div>
            </template>
  
            <div class="main-container inner-container" :style="{ 'padding-left': $q.screen.gt.sm ? '200px' : '0' }">
              <q-card class="desc-card">
                <q-badge
                  v-if="$q.screen.lt.md"
                  :color="completed ? 'green' : 'grey-4'"
                  :text-color="completed ? 'white' : 'grey-9'"
                  floating
                >
                  <q-icon v-if="completed" name="check" size="md"></q-icon>
                  <q-icon v-else name="lightbulb" size="md"></q-icon>
                </q-badge>
                <q-card-section class="row q-mt-xl" style="gap: 1rem; align-items: center !important;">
                  <template v-if="$q.screen.gt.sm">
                    <div v-if="completed" class="desc-badge bg-green text-weight-medium">
                      <q-icon name="check" color="white" size="lg"></q-icon>
                    </div>
                    <div v-else class="desc-badge bg-grey-4 text-weight-medium">
                      <q-icon name="lightbulb" color="grey-9" size="lg"></q-icon>
                    </div>
                  </template>
                  <div v-if="data.meta.desc" class="col text-body1" style="flex-grow: 1;" v-html="data.meta.desc"></div>
                </q-card-section>
              </q-card>

              <template v-if="hasFiles">
                <h4>Tutorial resources</h4>
                <FileDownloader v-for="file in data.meta.files" :key="file.name"
                  :name="file.name" :url="file.url" :desc="file.desc || ''"></FileDownloader>
              </template>
  
              <div v-for="(section, i) in data.content" :key="i">
                <h4 v-if="section.title" :id="$options.toSlug(section.title)">{{ section.title }}</h4>
                <div v-html="section.content" style="font-size: 0.95rem;"></div>
              </div>

              <div v-if="hasFinalFiles" class="q-my-xl">
                <FileDownloader v-for="file in data.meta.final_files" :key="file.name"
                  :name="file.name" :url="file.url" :desc="file.desc || ''"></FileDownloader>
              </div>
  
              <transition-group leave-active-class="animated fadeOut">
                <div v-if="completed !== 'full'" class="q-my-xl">
                  <q-separator v-if="hasFinalFiles" class="q-mb-xl"></q-separator>

                  <q-card flat bordered class="q-mt-lg">
                    <q-card-section class="row items-center">
                      <div class="col-shrink q-mr-md">
                        <q-btn
                          v-if="!completed"
                          class="check-button"
                          round
                          flat
                          @click="completeChapter"
                        >
                          <q-spinner color="info" v-if="completed === null"></q-spinner>
                        </q-btn>
                        <transition
                          appear
                          enter-active-class="animated bounceIn"
                        >
                          <q-avatar
                            v-if="completed"
                            icon="check"
                            color="positive"
                            text-color="white"
                            style="width: 40px; height: 40px;"
                          ></q-avatar>
                        </transition>
                      </div>
                      <div class="check-label col-grow text-body1">
                        <transition-group
                          enter-active-class="animated fadeInUp"
                          leave-active-class="animated fadeOutUp"
                        >
                          <div v-if="!completed">Mark this chapter as completed</div>
                          <div v-else class="text-green-7 text-weight-medium">Chapter completed!</div>
                        </transition-group>
                      </div>
                    </q-card-section>
                  </q-card>

                  <div v-if="$q.screen.lt.md" class="q-mt-lg justify-between" style="display: flex;">
                    <q-chip
                        v-if="prevChapter"
                        :clickable="prevChapter.accessible"
                        @click="() => { if (prevChapter.accessible) $router.push(prevChapter.route); }"
                        :color="prevChapter.accessible ? 'primary' : 'grey-4'"
                        :text-color="prevChapter.accessible ? 'white' : 'grey-9'"
                        icon="chevron_left"
                      >
                        Previous chapter
                      </q-chip>
                    <div v-else></div>
                    <q-chip
                        v-if="nextChapter"
                        :clickable="nextChapter.accessible"
                        @click="() => { if (nextChapter.accessible) $router.push(nextChapter.route); }"
                        :color="nextChapter.accessible ? 'primary' : 'grey-4'"
                        :text-color="nextChapter.accessible ? 'white' : 'grey-9'"
                        icon-right="chevron_right"
                      >
                        Next chapter
                      </q-chip>
                    <div v-else></div>
                  </div>
                </div>
              </transition-group>
            </div>
          </template>

          <Footer></Footer>
        </q-page>
      </q-page-container>

      <OverlayLockPanel
        v-if="!isAccessible"
        :requires-patreon="data.meta.requiresPatreon"
        class-label="text-h4 q-my-md"
        class-title="text-h2"
      ></OverlayLockPanel>
    </template>
    <template v-else>
      <q-inner-loading :showing="loading">
        <q-spinner-hourglass
          color="primary"
          size="4em"
          class=""
        />
      </q-inner-loading>
    </template>
  </div>
</template>

<script>
import slugify from 'slugify';
import { mapGetters, mapState } from 'vuex';
import {
  parseHtmlSections,
  highlightCode,
  createRouteLinks,
  blockPageScroll,
  fitContainerWithSidePanel
} from '@/helpers/html.js';
import { delay } from '@/helpers/misc.js';

import FileDownloader from '@/components/extra/FileDownloader.vue';
import Footer from '@/components/layout/Footer.vue';
import SideFloatingPanel from '@/components/layout/SideFloatingPanel.vue';
import OverlayLockPanel from '@/components/layout/OverlayLockPanel.vue';
import TutorialTOC from '@/components/tutorial/TutorialTOC.vue';

const toSlug = (name) => {
  return slugify(name).replaceAll('"', '');
};

export default {
  toSlug,
  name: 'Tutorial',
  components: {
    FileDownloader,
    Footer,
    SideFloatingPanel,
    OverlayLockPanel,

    TutorialTOC,
  },
  data() {
    return {
      loading: true,
      project: null,
      section: null,
      data: null,
      pageTitles: [],

      prevScroll: -1,
      showTOC: false,

      prevChapter: null,
      nextChapter: null,

      completed: false, /* or 'null' = in progress, or 'true' = validated with backend */
    };
  },
  computed: {
    ...mapGetters('projects', [
      'isTutorialAccessible',
    ]),
    ...mapState({
      projects: (state) => state.projects.projects,
      user: (state) => state.patreon.user,
    }),
    isAccessible() {
      if (!this.data) return false;
      const { slug, part } = this.$route.params;
      const sectionIndex = parseInt(part.substr(0, 2)) - 1;
      const chapterIndex = parseInt(part.substr(3, 2)) - 1;
      return this.isTutorialAccessible(slug, sectionIndex, chapterIndex);
    },
    pageStyle() {
      return {
        'max-height': '100vh',
        'overflow-y': this.isAccessible ? 'initial' : 'hidden',
      };
    },
    hasFiles() {
      return this.data.meta.files && this.data.meta.files.length > 0;
    },
    hasFinalFiles() {
      return this.data.meta.final_files && this.data.meta.final_files.length > 0;
    },
  },
  async created() {
    if (this.projects.length === 0)
      await this.$store.dispatch('projects/getProjects');

    const $this = this;
    window.setTimeout(async () => { $this.getTutorialData(); }, 200);
  },
  updated() {
    highlightCode();
    createRouteLinks(this);
    fitContainerWithSidePanel(32);
  },
  beforeUnmount() {
    blockPageScroll(false);
  },
  watch: {
    '$route.name': {
      handler: async function(newName, oldName) {
        blockPageScroll(false);

        if (newName === oldName && oldName === 'project-tutorial-part') {
          this.loading = true;
          this.completed = false;

          this.prevChapter = null;
          this.nextChapter = null;

          await this.getTutorialData();
          this.$nextTick(() => {
            highlightCode();
            createRouteLinks(this);
          });

          this.showTOC = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getTutorialData() {
      try {
        const { slug, part } = this.$route.params;
        this.project = this.$store.getters['projects/getProjectBySlug'](slug);
        let data;
        if (!this.project || this.project.type !== 'tutorial') {
          this.$router.push({ name: '404' });
        } else {
          if (!(part in this.project.parts)) {
            data = await this.$store.dispatch('projects/getProjectTutorialPartData', { slug, part });
            this.project = this.$store.getters['projects/getProjectBySlug'](slug); // re-get project to get all metadata, if not previously loaded
          } else  {
            data = this.project.parts[part];
          }
        }

        const { section, meta, content } = data;
        this.section = section;
        this.data = { meta, content: parseHtmlSections(content, 'h4') };

        const sectionIndex = parseInt(part.substr(0, 2)) - 1;
        const chapterIndex = parseInt(part.substr(3, 2)) - 1;
        const nSections = this.project.meta.sections.length;

        // get prev chapter
        let prevChapterSectionIndex = sectionIndex;
        let prevChapterChapterIndex = chapterIndex - 1;
        if (prevChapterChapterIndex === -1) {
          prevChapterSectionIndex--;
          if (prevChapterSectionIndex >= 0) {
            const prevSection = this.project.meta.sections[prevChapterSectionIndex];
            if (prevSection.quiz) prevChapterChapterIndex = 'quiz';
            else prevChapterChapterIndex = prevSection.items.length - 1;
          }
        }

        if (prevChapterChapterIndex !== -1) {
          const prevSectionIndexStr = (prevChapterSectionIndex + 1).toString().padStart(2, '0');
          const prevChapterIndexStr = prevChapterChapterIndex === 'quiz' ? 'quiz' : (prevChapterChapterIndex + 1).toString().padStart(2, '0');
          this.prevChapter = {
            route: `/projects/${slug}/${prevSectionIndexStr}-${prevChapterIndexStr}`,
            accessible: this.isTutorialAccessible(slug, prevChapterSectionIndex, prevChapterChapterIndex),
          };
        }
        
        // get next chapter
        let nextChapterSectionIndex = sectionIndex;
        let nextChapterChapterIndex = chapterIndex + 1;
        if (nextChapterChapterIndex === this.section.items.length) {
          if (this.section.quiz) nextChapterChapterIndex = 'quiz';
          else {
            nextChapterSectionIndex++;
            nextChapterChapterIndex = 0;
          }
        }

        if (nextChapterChapterIndex === 'quiz' || nextChapterSectionIndex < nSections) {
          const nextSectionIndexStr = (nextChapterSectionIndex + 1).toString().padStart(2, '0');
          const nextChapterIndexStr = nextChapterChapterIndex === 'quiz' ? 'quiz' : (nextChapterChapterIndex + 1).toString().padStart(2, '0');
          this.nextChapter = {
            route: `/projects/${slug}/${nextSectionIndexStr}-${nextChapterIndexStr}`,
            accessible: this.isTutorialAccessible(slug, nextChapterSectionIndex, nextChapterChapterIndex),
          };
        }
        
        // check for titles - add anchors
        this.pageTitles = [];
        for (const section of this.data.content)
          if (section.title) this.pageTitles.push({
            slug: toSlug(section.title),
            name: section.title,
          });
  
        // finalise page
        if (!this.isAccessible) {
          this.$store.commit('global/setPageScroll', { scrollable: false });
  
          // cut content to only keep first section in page code
          this.data.content = [this.data.content[0]];
        } else {
          if (this.user && (this.project.slug in this.user.tutorials)) {
            if (this.user.tutorials[this.project.slug].includes(`s${sectionIndex}_c${chapterIndex}`))
              this.completed = 'full';
          }
        }
  
        this.loading = false;
      } catch (e) {
        console.log(e)
        // this.$router.push({ name: '404' });
      }
    },
    async completeChapter() {
      this.completed = null; // (in progress)

      // try {
      //   await computeUserNotificationStack(this, this.$store.dispatch('users/markTutorialItemAsCompleted', {
      //     slug: this.project.slug,
      //     item: this.$route.params.part,
      //   }), completedChapterNotification);
      //   this.completed = true; // (validated by API)

      //   await delay(1500);
      //   this.completed = 'full'; // (removed from DOM)
      // } catch {
      //   return;
      // }
    },
    async toggleBottomBar(on) {
      this.showTOC = on;
      if (on) {
        this.prevScroll = window.scrollY;
        await delay(500);
        blockPageScroll(true);
      }
      else {
        blockPageScroll(false);
        window.scrollTo({ top: this.prevScroll, behavior: 'instant' });
      }
    },
  },
}
</script>

<style lang="sass" scoped>
#tutorial:deep(h6)
  margin: 1rem 0

#tutorial .inner-container:deep(img)
  max-width: 100% !important

.desc-badge
  min-width: 80px
  width: 80px
  height: 80px
  border-radius: 50%
  display: grid
  place-items: center
  font-size: 1.15rem

.check-button
  width: 40px
  height: 40px
  min-width: auto
  min-height: auto
  border: 1px solid $grey-7

.check-label
  position: relative
  height: 24px
.check-label > div
  position: absolute
  left: 0
  top: 0

// on mobiles
.desc-card:deep(.q-badge)
  height: 36px
  transform: translate(15%, -45%)
  border-bottom-left-radius: inherit
  border-bottom-right-radius: inherit
</style>
