import { createStore } from 'vuex';

import global from './modules/global.js';
import patreon from './modules/patreon.js';
import posts from './modules/posts.js';
import projects from './modules/projects.js';

export default createStore({
  modules: {
    global,
    patreon,
    posts,
    projects,
  },
  strict: process.env.NODE_ENV !== 'production'
});
