import axios from 'axios';
import jwtDecode from 'jwt-decode';

const state = () => ({
  user: null,
});

const mutations = {
  setUser(state, { user }) {
    state.user = user;
  },
};

const actions = {
  async checkToken({ commit }) {
    const token = window.localStorage.getItem('__patreon-token');
    if (!token) return;
    try {
      const { data } = await axios.post('/patreon-token', { token });
      axios.defaults.headers['Authorization'] = `Bearer ${token}`;
      commit('setUser', { user: { ...jwtDecode(token), ...data } });
    } catch (error) {
      window.localStorage.removeItem('__patreon-token');
      commit('setUser', { user: null });
    }
  },
  async logout({ commit }) {
    window.localStorage.removeItem('__patreon-token');
    delete axios.defaults.headers['Authorization'];
    commit('setUser', { user: null });
  },

  async connect() {
    const { data: user } = await axios.post('/patreon-login', {});
    console.log(user)
    return user;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
