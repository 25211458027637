<template>
  <div :class="['match-layout text-body1', isValid ? 'valid' : '']">
    <svg xmlns="http://www.w3.org/2000/svg" id="svg-container" class="svg-container"></svg>
    <template v-for="i in entries.length" :key="`row-${i}`">
      <div class="bordered rounded-borders q-pa-sm">{{ entries[i - 1] }}</div>
      <div class="connections-wrapper">
        <button :id="`in-${i - 1}`" @mousedown="dragConnection(i - 1, 'in')" @touchstart="dragConnection(i - 1, 'in')"
          :class="{ 'used': (i - 1) in modelValue }"></button>
        <button :id="`out-${i - 1}`" @mousedown="dragConnection(i - 1, 'out')" @touchstart="dragConnection(i - 1, 'in')"
          :class="{ 'used': getKeyForValue(i - 1) !== null }"></button>
      </div>
      <div class="bordered rounded-borders q-pa-sm">{{ outputs[i - 1] }}</div>
    </template>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { colors } from 'quasar';

const SVG_NS = 'http://www.w3.org/2000/svg';

export default {
  name: 'QuizMatchQuestion',
  props: {
    modelValue: { type: Object, required: true },
    entries: { type: Object, required: true },
    outputs: { type: Object, required: true },
    isValid: { type: [null, Boolean], default: null },
    color: { type: String, default: 'primary' },
  },
  data() {
    return {
      dragData: null,
      draggedLine: null,
      draggedSide: null,
      draggedInIndex: null,
      svgOffset: null,
      lines: {},
    };
  },
  computed: {
    col() {
      return colors.getPaletteColor(this.color);
    },
  },
  mounted() {
    this.setSvgOffset();
  },
  methods: {
    setSvgOffset() {
      const svgContainer = document.getElementById('svg-container');
      const svgBbox = svgContainer.getBoundingClientRect();
      this.svgOffset = { x: svgBbox.left, y: svgBbox.top };
    },
    // for parent component: in case it's finished, auto-set answer
    //   (value of the form: {0: 1, 1: 2, 2: 0})
    setAnswer(value) {
      this.$nextTick(() => {
        for (const key of Object.keys(value)) {
          const line = this.getLine(key, 'in');
          
          let point = document.getElementById(`in-${key}`);
          let bbox = point.getBoundingClientRect();
          let x = bbox.left + bbox.width / 2 - this.svgOffset.x;
          let y = bbox.top + bbox.height / 2 - this.svgOffset.y;
          line.setAttribute('x1', x); line.setAttribute('y1', y);
    
          point = document.getElementById(`out-${value[key]}`);
          bbox = point.getBoundingClientRect();
          x = bbox.left + bbox.width / 2 - this.svgOffset.x;
          y = bbox.top + bbox.height / 2 - this.svgOffset.y;
          line.setAttribute('x2', x); line.setAttribute('y2', y);
        }
      });
    },

    setValueKey(key, value) {
      const data = { ...this.modelValue };
      data[key] = value;
      this.$emit('update:model-value', data);
    },
    removeKey(key) {
      const data = { ...this.modelValue };
      delete data[key];
      this.$emit('update:model-value', data);
    },
    getKeyForValue(val, obj = null) {
      if (!obj) obj = this.modelValue;
      for (const [key, value] of Object.entries(obj)) {
        if (value === val) return key;
      }
      return null;
    },
    createLine() {
      const line = document.createElementNS(SVG_NS, 'line');
      line.classList.add('connect-line');
      document.getElementById('svg-container').append(line);
      line.onclick = () => {
        console.log('here')
        this.deleteConnection(line);
      }
      return line;
    },
    getLine(index, side) {
      if (side === 'in') {
        if (!(index in this.lines))
          this.lines[index] = this.createLine();
        return this.lines[index];
      } else {
        const k = this.getKeyForValue(index);
        if (k) return this.lines[k];
        return this.createLine();
      }
    },
    dragConnection(index, side) {
      if (this.isValid) return; // already correct: can't change anymore

      const line = this.getLine(index, side);
      const oppositeSide = (side === 'in') ? 'out' : 'in';
      this.dragData = { line, oppositeSide, startIndex: index };

      const point = document.getElementById(`${side}-${index}`);
      const bbox = point.getBoundingClientRect();
      const x = bbox.left + bbox.width / 2 - this.svgOffset.x;
      let y = bbox.top + bbox.height / 2 - this.svgOffset.y;
      if (y < 0) {
        // . block was initially outside of viewport: need to reupdate the svgOffset
        this.svgOffset.y = document.getElementById('svg-container').getBoundingClientRect().top;
        y = bbox.top + bbox.height / 2 - this.svgOffset.y;
      }

      // going from 'in' to 'out'
      if (oppositeSide === 'out') {
        // if it's the first time: initialise the line
        if (!(index in this.modelValue)) {
          line.setAttribute('x1', x); line.setAttribute('y1', y);
          line.setAttribute('x2', x); line.setAttribute('y2', y);
        }
        // if there is already a line: reverse the connection
        else {
          this.dragData.oppositeSide = 'in';
          this.dragData.startIndex = this.modelValue[index];
        }
      }
      // going from 'out' to 'in'
      else {
        // if it's the first time: initialise the line
        const k = this.getKeyForValue(index);
        if (!(k in this.modelValue)) {
          line.setAttribute('x1', x); line.setAttribute('y1', y);
          line.setAttribute('x2', x); line.setAttribute('y2', y);
        }
        // if there is already a line: reverse the connection
        else {
          this.dragData = {
            line: this.getLine(k, 'in'),
            oppositeSide: 'out',
            startIndex: k,
          };
        }
      }

      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
      window.addEventListener('touchmove', this.onTouchMove);
      window.addEventListener('touchend', this.onTouchUp);

      disableBodyScroll(document.querySelector('.inner-container'));
    },
    deleteConnection(line) {
      if (this.isValid) return; // already correct: can't change anymore

      const key = this.getKeyForValue(line, this.lines);
      this.removeKey(key);
      delete this.lines[key];
      line.remove();
    },
    onMouseMove(event) {
      this.onInputMove(event, 'mouse');
    },
    onTouchMove(event) {
      this.onInputMove(event, 'touch');
    },
    onInputMove(event, inputType) {
      let { clientX: x, clientY: y } = inputType === 'mouse' ? event : event.changedTouches[0];
      x -= this.svgOffset.x;
      y -= this.svgOffset.y;
      const { line } = this.dragData;
      if (this.dragData.oppositeSide === 'in') {
        line.setAttribute('x1', x);
        line.setAttribute('y1', y);
      } else {
        line.setAttribute('x2', x);
        line.setAttribute('y2', y);
      }
    },
    onMouseUp(event) {
      this.onInputUp(event, 'mouse');
    },
    onTouchUp(event) {
      this.onInputUp(event, 'touch');
    },
    onInputUp(event, inputType) {
      const { clientX: x, clientY: y } = inputType === 'mouse' ? event : event.changedTouches[0];
      const { line, startIndex, oppositeSide } = this.dragData;

      // check if the mouse is over any out point:
      let connected = false;
      if (oppositeSide === 'out') {
        // check out bboxes
        for (let i = 0; i < this.entries.length; i++) {
          const outBbox = document.getElementById(`out-${i}`).getBoundingClientRect();
          if (this.bboxContains(outBbox, x, y)) {
            // set data
            this.setValueKey(startIndex, i);
            // snap line to connector
            line.setAttribute('x2', outBbox.left + outBbox.width / 2 - this.svgOffset.x);
            line.setAttribute('y2', outBbox.top + outBbox.height / 2 - this.svgOffset.y);

            connected = true;
            break;
          }
        }
      } else {
        // check in bboxes
        for (let i = 0; i < this.entries.length; i++) {
          const inBbox = document.getElementById(`in-${i}`).getBoundingClientRect();
          if (this.bboxContains(inBbox, x, y)) {
            // set data
            //  (if line was already connected to an input: transfer or clean the info)
            const prevKey = this.getKeyForValue(startIndex);
            if (prevKey) {
              this.removeKey(prevKey);
              this.lines[i] = this.lines[prevKey];
              delete this.lines[prevKey];
            } else {
              this.lines[i] = line;
            }
            //  (delay by one frame to avoid wrong data update)
            this.$nextTick(() => { this.setValueKey(i, startIndex); });
            // snap line to connector
            line.setAttribute('x1', inBbox.left + inBbox.width / 2 - this.svgOffset.x);
            line.setAttribute('y1', inBbox.top + inBbox.height / 2 - this.svgOffset.y);

            connected = true;
            break;
          }
        }
      }

      // else:
      if (!connected) {
        // snap the line back to the entry point to make it disappear, and:
        // - don't set any data if it wasn't connected before,
        // - or disconnect it in the current data
        line.setAttribute('x2', line.getAttribute('x1'));
        line.setAttribute('y2', line.getAttribute('y1'));
        if (oppositeSide === 'out') {
          if (startIndex in this.modelValue) {
            this.removeKey(startIndex);
            delete this.lines[startIndex];
          }
        } else {
          const k = this.getKeyForValue(startIndex);
          if (k in this.modelValue) {
            this.removeKey(k);
            delete this.lines[k];
          }
        }
      }

      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);
      window.removeEventListener('touchmove', this.onTouchMove);
      window.removeEventListener('touchend', this.onTouchUp);

      document.activeElement.blur();
      enableBodyScroll(document.querySelector('.inner-container'));
    },
    bboxContains(bbox, x, y) {
      return bbox.left <= x && x <= bbox.left + bbox.width && bbox.top <= y && y <= bbox.top + bbox.height;
    }
  },
}
</script>

<style lang="sass" scoped>
.match-layout
  --display-color: v-bind('col')
  display: grid
  grid-template-columns: auto 65px auto
  margin-top: 1rem
  gap: 0.5rem
  padding-left: 15px
  position: relative
.match-layout button
  display: block
  width: 100%
.match-layout .bordered
  border: 1px solid $grey-5

.svg-container
  position: absolute
  width: 100%
  height: 100%
:deep(.connect-line)
  stroke: var(--display-color)
  stroke-width: 4
.match-layout:not(.valid):deep(.connect-line)
  cursor: pointer
:deep(.connect-line:hover)
  stroke: $amber

.connections-wrapper
  display: flex
  justify-content: space-between
  align-items: center
.connections-wrapper button
  --size: 20px
  width: var(--size)
  height: var(--size)
  background-color: white
  border: 2px solid $grey-8
  border-radius: 50%
  outline: none
  z-index: 9
.connections-wrapper button:hover,
.connections-wrapper button.used
  border: 4px solid var(--display-color)
.connections-wrapper button.used
  background-color: var(--display-color)
</style>
