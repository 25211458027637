<template>
  <q-page-container>
    <q-page class="center-container inner-container">
      <q-card class="q-px-lg q-pb-xl q-mt-xl" style="max-width: 66vw;">
        <q-card-section>
          <h3 class="q-my-md text-center">
            <img :src="$options.getRelativeImg('/mp-logo_black.png')" style="height: 60px; width: auto; vertical-align: middle;" />
            Reset password
          </h3>
        </q-card-section>

        <q-banner v-if="expired" :class="['bg-negative', 'q-mb-md text-white']"
          style="margin: 1rem -24px 2rem -24px;">
          <template v-slot:avatar>
            <q-icon name="error" color="white" />
          </template>
          <span class="text-weight-medium text-body1">
            This token is invalid. You should try and go back to <a href="http://minapecheux.com" style="color: inherit;">minapecheux.com</a> and ask for a new password reset email :)
          </span>
        </q-banner>

        <q-form
          v-else-if="!result || result.type === 'negative'"
          @submit.prevent.stop="resetPassword">
          <q-input
            v-model="username"
            ref="usernameRef"
            debounce="400"
            outlined
            lazy-rules
            label="Username"
            :rules="[val => !!val || 'Required']"
            class="q-my-sm"
          >
            <template v-slot:prepend>
              <q-icon name="key" />
            </template>
          </q-input>
          <q-input
            v-model="newPassword"
            ref="newPasswordRef"
            debounce="400"
            outlined
            lazy-rules
            label="New password"
            type="password"
            :rules="[val => !!val || 'Password cannot be empty']"
            class="q-my-sm"
          >
            <template v-slot:prepend>
              <q-icon name="key" />
            </template>
          </q-input>
          <PasswordStrengthMeter :password="newPassword"></PasswordStrengthMeter>
          <q-input
            v-model="newPasswordConfirm"
            ref="newPasswordConfirmRef"
            debounce="400"
            outlined
            lazy-rules
            label="Confirm password"
            type="password"
            :rules="[
              val => !!val || 'Password cannot be empty',
              val => val === newPassword || 'Passwords must match',
            ]"
          >
            <template v-slot:prepend>
              <q-icon name="key" />
            </template>
          </q-input>

          <div class=row>
            <q-space></q-space>
            <q-btn push color="primary" type="submit">reset</q-btn>
          </div>
        </q-form>

        <template v-if="result">
          <q-banner :class="[`bg-${result.type}`, 'q-mb-md text-white']"
            style="margin: 1rem -24px 2rem -24px;">
            <template v-slot:avatar>
              <q-icon :name="result.icon" color="white" />
            </template>
            <span class="text-weight-medium text-body1">{{ result.msg }}</span>
          </q-banner>

          <q-btn flat class="q-mt-md text-body1 text-weight-medium" style="display: flex;" :to="{ name: 'library' }">Go back to the site</q-btn>
        </template>
      </q-card>
    </q-page>
  </q-page-container>
</template>

<script>
import { getRelativeImg } from '@/helpers/html.js';

import PasswordStrengthMeter from '@/components/extra/PasswordStrengthMeter.vue';

export default {
  getRelativeImg,
  name: 'ResetPassword',
  components: { PasswordStrengthMeter },
  data() {
    return {
      username: '',
      newPassword: '',
      newPasswordConfirm: '',
      result: null,
      expired: false,
    };
  },
  async created() {
    const expired = await this.$store.dispatch('users/checkTokenExpired', {
      token: this.$route.params.token,
    });
    if (expired) {
      this.expired = true;
    }
  },
  methods: {
    async resetPassword() {
      this.$refs.usernameRef.validate();
      this.$refs.newPasswordRef.validate();
      this.$refs.newPasswordConfirmRef.validate();

      try {
        await this.$store.dispatch('users/resetPassword', {
          token: this.$route.params.token,
          username: this.username,
          password: this.newPassword,
        });
        this.result = {
          type: 'positive',
          icon: 'check',
          msg: 'Password reset!',
        };
      } catch (err) {
        this.result = {
          type: 'negative',
          icon: 'error',
          msg: err.response.data.error,
        };
      }
    }
  },
}
</script>

<style lang="sass" scoped>
.center-container
  width: 100vw
  height: 100vh
  display: grid
  place-items: center
</style>
