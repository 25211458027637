<template>
  <template v-if="horizontal">
    <div :class="`${isClickable ? 'clickable-bubble' : ''} horizontal-bubble`">
      <q-avatar
        :size="size"
        :font-size="fontSize"
        color="primary"
        text-color="white"
        :icon="data.icon"
        @click.stop="onClick"
        :style="style"
      ></q-avatar>
      <div>
        <template v-if="rawHtml">
          <component :is="titleComponent" class="q-my-none" v-html="data.title"></component>
          <div v-if="data.desc" class="text-body1" v-html="data.desc"></div>
        </template>
        <template v-else>
          <component :is="titleComponent" class="q-my-none">{{ data.title }}</component>
          <div v-if="data.desc" class="text-body1">{{ data.desc }}</div>
        </template>
      </div>
    </div>
  </template>
  <template v-else>
    <div :class="`${isClickable ? 'clickable-bubble' : ''} text-center ${className}`">
      <q-avatar
        :size="size"
        :font-size="fontSize"
        color="primary"
        text-color="white"
        :icon="data.icon"
        @click.stop="onClick"
        :style="style"
      ></q-avatar>
      <template v-if="rawHtml">
        <component :is="titleComponent" class="q-my-md" v-html="data.title"></component>
        <div v-if="data.desc" class="text-subtitle1 text-weight-medium" v-html="data.desc"></div>
      </template>
      <template v-else>
        <component :is="titleComponent" class="q-my-md">{{ data.title }}</component>
        <div v-if="data.desc" class="text-subtitle1 text-weight-medium">{{ data.desc }}</div>
      </template>
    </div>
  </template>
</template>

<script>
export default {
  name: 'HighlightBubble',
  props: {
    data: { type: Object, default: () => {} },
    titleComponent: { type: String, default: 'div' },
    className: { type: String, default: 'col' },
    size: { type: String, default: '80px' },
    fontSize: { type: String, default: '42px' },
    active: { type: Boolean, default: true },
    isClickable: { type: Boolean, default: false },
    rawHtml: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false },
  },
  computed: {
    style() {
      return { opacity: this.active ? 1 : 0.6 };
    },
  },
  methods: {
    onClick() {
      if (this.isClickable) this.$emit('click-bubble');
    },
  },
}
</script>

<style lang="sass" scoped>
.clickable-bubble:deep(.q-avatar)
  cursor: pointer
  transform: none
  transition: transform 0.2s ease
.clickable-bubble:deep(.q-avatar:hover)
  transform: scale(1.05)

.horizontal-bubble
  display: grid
  grid-template-columns: auto 1fr
  column-gap: 1rem
  padding: 0 1rem
</style>
