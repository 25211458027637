<template>
  <router-link v-if="!disabled" v-bind="$attrs">
    <slot></slot>
  </router-link>
  <span v-else v-bind="$attrs">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'OptionalRouterLink',
  props: {
    disabled: { type: Boolean, required: true },
  },
}
</script>
