<template>
  <svg viewBox="0 0 40 22.725" :width="size" :height="size" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M 22.706562,11.353281 C 23.172904,20.490791 11.12786,26.291379 4.2746261,20.229634 -3.1601268,14.89709 -0.18524641,1.8632876 8.8269383,0.28465046 15.293787,-1.4129546 22.318346,3.7669869 22.667785,10.415734 Z"></path>
    <path d="M 35.004121,11.353281 C 34.877529,15.238134 34.044719,21.197992 29.38452,22.033062 23.835293,20.702087 23.478827,13.223462 23.956401,8.5891501 24.350996,5.3962005 26.199222,-0.05839761 30.360353,0.83574992 34.152805,2.5620986 34.89304,7.6760708 35.004121,11.353281 Z"></path>
    <path d="m 40.000005,11.353281 c -0.341836,3.093693 0.36252,7.280233 -1.96346,9.621425 -2.668735,-3.162058 -1.693057,-8.207015 -1.896557,-12.1116329 0.498711,-2.1426087 -0.186219,-5.9506862 2.067684,-7.0946044 2.015533,2.5795482 1.526197,6.4762141 1.792333,9.5848123 z"></path>
  </svg>
</template>

<script>
export default {
  name: 'IconMedium',
  props: {
    color: { type: String, default: '#ffffff' },
    size: { type: Number, default: 24 },
  }
}
</script>
