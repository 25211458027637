<template>
  <svg viewBox="0 0 23 16" :width="size" :height="size" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M14.8808205,7.71692308 C12.8533333,6.6334359 8.92758974,4.59733333 8.92758974,4.59733333 L14.161641,8.11528205 C14.1620513,8.11528205 13.2082051,8.64410256 14.8808205,7.71692308 Z" id="Path" fill="#EDEDED"></path>
    <path d="M9.30912821,15.6890256 C5.09784615,15.6114872 3.65948718,15.5417436 2.77538462,15.3595897 C2.17723077,15.2385641 1.6574359,14.9698462 1.27630769,14.5801026 C0.98174359,14.2851282 0.747487179,13.8350769 0.56574359,13.2106667 C0.409846154,12.6904615 0.349128205,12.257641 0.262564103,11.2004103 C0.130051282,8.81435897 0.0984615385,6.86358974 0.262564103,4.68389744 C0.397948718,3.48020513 0.463589744,2.05128205 1.36287179,1.21764103 C1.78748718,0.827487179 2.2814359,0.576410256 2.83610256,0.472615385 C3.7025641,0.308102564 7.39405128,0.178051282 11.2155897,0.178051282 C15.0285128,0.178051282 18.7282051,0.308102564 19.5954872,0.472615385 C20.2884103,0.602666667 20.9382564,0.992410256 21.3197949,1.49497436 C22.1403077,2.78564103 22.1546667,4.3905641 22.2379487,5.64594872 C22.2724103,6.24410256 22.2724103,9.64061538 22.2379487,10.2387692 C22.1083077,12.2227692 22.0041026,12.9247179 21.7095385,13.6525128 C21.5273846,14.112 21.3718974,14.3544615 21.1027692,14.6231795 C20.6806824,15.0367007 20.1425885,15.3117583 19.5602051,15.4116923 C15.9158974,15.6857436 12.8213333,15.7452308 9.30912821,15.6890256 Z M14.8898462,7.71692308 C12.862359,6.6334359 10.9210256,5.62871795 8.93661538,4.58871795 L8.93661538,10.8106667 C11.0248205,9.67548718 13.2262564,8.63548718 14.8984615,7.70830769 L14.8898462,7.71692308 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
  </svg>
</template>

<script>
export default {
  name: 'IconYoutube',
  props: {
    color: { type: String, default: '#ffffff' },
    size: { type: Number, default: 24 },
  }
}
</script>
