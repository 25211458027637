<template>
  <div id="app">
    <q-layout view="hHh lpR fFf" :class="{ 'non-scrollable': !pageIsScrollable }">
      <template v-if="$route.name == 'reset-password'">
        <router-view></router-view>
      </template>
      <template v-else>
        <Navbar></Navbar>
        <router-view></router-view>
        <Footer v-if="showFooter"></Footer>
        <CrossPageMusic></CrossPageMusic>

        <Notifications enter-class="bounceIn" />
      </template>
    </q-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CrossPageMusic from '@/components/extra/CrossPageMusic.vue';
import Navbar from '@/components/layout/Navbar.vue';
import Footer from '@/components/layout/Footer.vue';

export default {
  name: 'App',
  components: {
    CrossPageMusic,
    Navbar,
    Footer,
  },
  computed: {
    ...mapState({
      pageIsScrollable: (state) => state.global.pageIsScrollable,
    }),
    bannerHeight() {
      return window.innerHeight - 50;
    },
    showFooter() {
      const { name: route } = this.$route;
      return (
        route !== 'project' &&
        route !== 'project-tutorial-part' &&
        route !== 'project-tutorial-quiz' &&
        route !== 'blog-post'
      )
    },
  },
  created() {
    this.$store.dispatch('patreon/checkToken');
  },
}
</script>

<style>
.q-layout.non-scrollable {
  overflow-y: hidden;
}

.main-container {
  padding-bottom: 8rem;
}

body.screen--xs,
body.screen--sm {

  .inner-container,
  .inner-large-container,
  .inner-container pre,
  .inner-large-container pre {
    margin: 0 auto;
    max-width: 92vw;
  }
}

body.screen--md,
body.screen--lg,
body.screen--xl {
  .inner-container {
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .inner-large-container {
    margin: 0 auto;
    max-width: 1400px;
  }
  
  .inner-container pre {
    max-width: 1200px !important;
  }
  .inner-large-container pre {
    max-width: 1400px !important;
  }
}

.banner-wrapper,
.banner-wrapper .q-img {
  height: 100%;
}

#project .inner-container img,
#post .inner-container img {
  max-width: 100% !important;
  height: auto;
  margin: 1rem auto;
  display: flex;
}

#project .inner-container video,
#post .inner-container video {
  max-width: 100%;
}

/* from: https://github.com/quasarframework/quasar/blob/dev/extras/animate/ */
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-duration: calc(var(--animate-duration) * 0.75);
  animation-name: bounceIn;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}
</style>

<style lang="sass">
.notif
  height: 80px

.notif .q-notification__content > .q-icon
  display: grid
  place-items: center
  min-width: 60px
  height: 60px
  background-color: $primary
  font-size: 40px

.notif.notif--positive .q-notification__content > .q-icon
  background-color: $green
.notif.notif--negative .q-notification__content > .q-icon
  background-color: $red

// for mobiles
.bottom-bar
  z-index: 10
  position: fixed
  bottom: 0
  left: -8px
  right: -8px
  background-color: white
  padding: 0.5rem calc(0.5rem + 8px)
  display: flex
  justify-content: space-between
  transform: translateY(100%)
  transition: all 0.25s ease-in-out, max-height 0.5s ease-in-out
  box-shadow: 0 0px 20px 0px $grey-5

.bottom-bar.visible
  transform: translateY(0)

.bottom-bar .back-button:deep(.q-icon)
  margin-right: 2px

.bottom-bar.extendable
  z-index: 10000
  height: calc(100vh - 50px)
  max-height: 50px
.bottom-bar.extendable.extended
  transform: translateY(0)
  max-height: calc(100vh - 50px)
</style>
