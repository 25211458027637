<template>
  <div
    class="overlay-panel"
    :class="['overlay-panel', hovered ? 'hovered' : '', fullPage ? 'full-page' : '']"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <div class="overlay-lock">
      <q-icon name="lock" size="6rem"></q-icon>
    </div>
    <div v-if="hovered" class="overlay-content text-white text-center">
      <div :class="[classLabel, 'text-uppercase', 'non-selectable']">
        <template v-if="!user">
          <div class="text-uppercase text-weight-medium non-selectable q-mb-md" style="font-size: 1.3rem;">requires a patreon</div>
          <q-btn color="red" size="lg" @click="connectToPatreon">Log in</q-btn>
        </template>
        <template v-else-if="user.rank < requiresPatreon">
          <div class="text-uppercase text-weight-medium non-selectable q-mb-md" style="font-size: 1.3rem;">requires patreon rank</div>
          <q-avatar class="text-weight-medium" color="white" text-color="grey-9">"{{ $options.PATREON_RANKS[level - 1] }}"</q-avatar>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { PATREON_RANKS } from '@/helpers/patreon';

export default {
  PATREON_RANKS,
  name: 'OverlayLockPanel',
  props: {
    requiresPatreon: { type: Number },
    classLabel: { type: String, required: true },
    classTitle: { type: String, required: true },
    fullPage: { type: Boolean, default: true },
  },
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.patreon.user,
    }),
  },
  methods: {
    async connectToPatreon() {
      const user = await this.$store.dispatch('patreon/connect');
      console.log('>', user)
    },
  },
}
</script>

<style lang="sass">
.overlay-panel
  z-index: 100
  background-color: rgba(60, 60, 60, 0.55)
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  display: grid
  place-items: center
  transition: background-color 0.2s linear
  max-height: 100vh
.overlay-panel.hovered
  background-color: rgba(10, 10, 10, 0.75)

.overlay-lock
  color: $grey-2
  border: 8px solid $grey-2
  border-radius: 50%
  opacity: 0.85
  padding: 0.5rem
  transform: none
  transition: transform 0.35s ease
.overlay-panel.hovered .overlay-lock
  transform: translateY(-50%)

.overlay-content
  position: absolute
  width: 100%
  top: 60%
  left: 50%
  transform: translateX(-50%)
  opacity: 0
  transition: opacity 0.35s ease
.overlay-panel.full-page .overlay-content
  top: 50%
.overlay-panel.hovered .overlay-content
  opacity: 1
</style>
