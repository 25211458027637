<template>
  <div :class="[mode === 'full' ? 'q-my-md' : '', $q.screen.gt.md ? (mode === 'full' ? 'q-mx-xl' : '') : 'q-mx-sm']">
    <template v-if="mode === 'full'">
      <q-banner v-if="!user" inline-actions class="bg-info text-white q-mb-lg">
        <template v-slot:avatar>
          <q-icon name="info" color="white"></q-icon>
        </template>
        <div class="text-weight-medium">
          Don't forget to log in with your Patreon account if you want to keep track of your progression and have access to all the sections :)
        </div>
        <template v-slot:action>
          <q-btn flat color="white" label="log in" @click="connectToPatreon"></q-btn>
        </template>
      </q-banner>
  
      <q-list bordered class="rounded-borders">
        <template v-for="(section, index) in data" :key="index">
          <q-item>
            <q-item-section v-if="finishedItems.sections.includes(index)" avatar>
              <q-avatar
                v-if="finishedItems.sections.includes(index)"
                color="green" text-color="white" icon="check"></q-avatar>
              <q-avatar v-else color="grey-4" text-color="grey-9">{{ index + 1 }}</q-avatar>
            </q-item-section>
            <q-item-section class="row justify-between items-center" style="flex-direction: row;">
              <div class="text-h6">{{ section.name }}</div>
              <div v-if="section.time" class="row items-center text-weight-medium text-grey-8">
                <q-icon name="schedule" size="sm" class="q-pa-xs"></q-icon> {{ section.time }}
              </div>
            </q-item-section>
          </q-item>
          <q-item
            v-for="(item, i) in section.items"
            :key="i"
            :class="['inner-item', $q.screen.gt.sm ? 'q-pl-xl' : 'q-pl-lg']"
            :to="getChapterRoute(index, i)"
            :clickable="!lockedItems.includes(`${index}-${i}`)"
          >
            <q-item-section avatar>
              <q-avatar
                v-if="finishedItems.chapters[index]?.includes(i)"
                icon="check" text-color="white" color="green" size="lg"
              ></q-avatar>
              <q-avatar
                v-else
                :icon="lockedItems.includes(`${index}-${i}`) ? 'lock' : 'stop'"
                :text-color="lockedItems.includes(`${index}-${i}`) ? 'grey-9' : 'grey-4'"
                color="grey-4" size="lg"
              ></q-avatar>
            </q-item-section>
            <q-item-section style="font-size: 1rem;">
              {{ item.name }}
            </q-item-section>
          </q-item>
          <q-item
            v-if="section.quiz"
            :class="['inner-item', $q.screen.gt.sm ? 'q-pl-xl' : 'q-pl-lg']"
            :to="getChapterRoute(index, 'quiz')"
            :clickable="!lockedItems.includes(`${index}-quiz`)"
          >
            <q-item-section avatar>
              <q-avatar
                v-if="finishedItems.chapters[index]?.includes('quiz')"
                icon="check"
                text-color="white"
                color="green"
                size="lg"
              ></q-avatar>
              <q-avatar
                v-else
                :icon="lockedItems.includes(`${index}-quiz`) ? 'lock' : 'star'"
                text-color="grey-9" color="grey-4" size="lg"
              ></q-avatar>
            </q-item-section>
            <q-item-section style="font-size: 1rem;">
              Self-evaluation quiz
            </q-item-section>
          </q-item>
    
          <q-separator></q-separator>
        </template>
      </q-list>
    </template>
    <q-list v-else-if="mode === 'sidebar'" :style="sidebarStyle">
      <q-item clickable v-ripple class="text-body1 text-weight-medium" :to="`/projects/${projectSlug}`">
        <q-item-section avatar>
          <q-avatar size="md" color="grey-4" text-color="grey-9" icon="home"></q-avatar>
        </q-item-section>
        <q-item-section>Project page</q-item-section>
      </q-item>
      <q-expansion-item v-for="(section, index) in data" :key="index" v-model="expandedSections[index]">
        <template v-slot:header>
          <q-item-section avatar>
            <q-avatar
              v-if="finishedItems.sections.includes(index)" size="md"
              color="green" text-color="white" icon="check"></q-avatar>
            <q-avatar v-else size="md" color="grey-4" text-color="grey-9" class="text-weight-medium">{{ index + 1 }}</q-avatar>
          </q-item-section>
          <q-item-section :class="['text-body1', expandedSections[index] ? 'text-weight-medium' : '']">
            {{ section.name }}
          </q-item-section>
        </template>
        <q-card>
          <q-card-section class="q-mt-none">
            <q-list>
              <div v-for="(item, i) in section.items" :key="i">
                <q-item
                  class="inner-item"
                  :to="getChapterRoute(index, i)"
                  :clickable="!lockedItems.includes(`${index}-${i}`)"
                >
                  <q-item-section avatar>
                    <q-avatar
                      v-if="finishedItems.chapters[index]?.includes(i)"
                      icon="check"
                      text-color="white"
                      color="green"
                      size="md"
                    ></q-avatar>
                    <q-avatar
                      v-else
                      :icon="lockedItems.includes(`${index}-${i}`) ? 'lock' : 'stop'"
                      :text-color="lockedItems.includes(`${index}-${i}`) ? 'grey-9' : 'grey-4'"
                      color="grey-4"
                      size="md"
                    ></q-avatar>
                  </q-item-section>
                  <q-item-section style="font-size: 1rem;"><q-item-label>{{ item.name }}</q-item-label></q-item-section>
                </q-item>
                <div v-if="index === sectionIndex && i === chapterIndex" class="page-titles q-py-sm">
                  <div v-for="title in pageTitles" :key="title.slug">
                    <q-item
                      :inset-level="0.1"
                      clickable
                      v-ripple
                      dense
                      @click="scrollTo(title.slug)"
                    >
                      <q-item-section>
                        <q-item-label>{{ title.name }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </div>
              <q-item class="inner-item"
                v-if="section.quiz"
                :to="getChapterRoute(index, 'quiz')"
                :clickable="!lockedItems.includes(`${index}-quiz`)"
              >
                <q-item-section avatar>
                  <q-avatar
                    v-if="finishedItems.chapters[index]?.includes('quiz')"
                    icon="check"
                    text-color="white"
                    color="green"
                    size="sm"
                  ></q-avatar>
                  <q-avatar
                    v-else
                    :icon="lockedItems.includes(`${index}-quiz`) ? 'lock' : 'star'"
                    text-color="grey-9" color="grey-4" size="md"
                  ></q-avatar>
                </q-item-section>
                <q-item-section style="font-size: 1rem;">
                  <q-item-label>Self-evaluation quiz</q-item-label>                  
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TutorialTOC',
  props: {
    projectSlug: { type: String, required: true },
    data: { type: Object, required: true },
    mode: { type: String, default: 'full' /* or 'sidebar' */ },
    pageTitles: { type: Array, default: () => [] },
    maxHeight: { type: [null, Number], default: null },
  },
  data() {
    return {
      expandedSections: [],
      sectionIndex: null,
      chapterIndex: null,
      project: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.patreon.user,
    }),
    finishedItems() {
      const sections = [];
      const chapters = {};
      if (this.user && this.projectSlug in this.user.tutorials) {
        for (const element of this.user.tutorials[this.projectSlug]) {
          if (/^s(\d+)$/.test(element)) {
            sections.push(parseInt(element.substr(1)));
          }
          else {
            if (element.includes('quiz')) {
              const match = element.match(/^s(\d+)_quiz$/);
              const s = parseInt(match[1]);
              if (!(s in chapters)) chapters[s] = [];
              chapters[s].push('quiz');
            } else {
              if (element.includes('_q')) continue;
              const match = element.match(/^s(\d+)_c(\d+)$/);
              const s = parseInt(match[1]);
              const c = parseInt(match[2]);
              if (!(s in chapters)) chapters[s] = [];
              chapters[s].push(c);
            }
          }
        }
      }

      return { sections, chapters };
    },
    lockedItems() {
      const { sections } = this.project.meta;
      
      const locked = [];
      for (const [index, section] of Object.entries(sections)) {
        for (const [i, chapter] of Object.entries(section.items)) {
          if (chapter.requiresPatreon) {
            if (
              (!this.user && chapter.requiresPatreon > 0) ||
              chapter.requiresPatreon > this.user.rank
            ) {
              locked.push(`${index}-${i}`);
            }
          }
        }
        if ('quiz' in section) {
          if (!this.user || (section.quiz.requiresPatreon && section.quiz.requiresPatreon > this.user.rank)) {
            locked.push(`${index}-quiz`);
          }
        }
      }

      return locked;
    },
    sidebarStyle() {
      return { 'max-height': `${(this.maxHeight || this.$q.screen.height - 248)}px`, 'overflow-y': 'auto' };
    }
  },
  created() {
    this.openCurrentChapter();
  },
  watch: {
    '$route.name': {
      handler: async function(newName, oldName) {
        if (newName === oldName && oldName === 'project-tutorial-part') {
          this.openCurrentChapter();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    openCurrentChapter() {
      if (this.mode === 'sidebar') {
        const { part } = this.$route.params;
        this.sectionIndex = parseInt(part.substr(0, 2)) - 1;
        this.chapterIndex = parseInt(part.substr(3, 2)) - 1;
        this.expandedSections = this.data.map((_, i) => i === this.sectionIndex);
      }

      this.project = this.$store.getters['projects/getProjectBySlug'](this.projectSlug);
    },
    async connectToPatreon() {
      const user = await this.$store.dispatch('patreon/connect');
      console.log('>', user)
    },
    clickItem(sectionIndex, chapterIndex) {
      if (!this.lockedItems.includes(`${sectionIndex}-${chapterIndex}`))
        this.getChapterRoute(sectionIndex, chapterIndex);
    },
    getChapterRoute(sectionIndex, chapterIndex) {
      if (this.lockedItems.includes(`${sectionIndex}-${chapterIndex}`)) return null;

      const section = (sectionIndex + 1).toString().padStart(2, '0');
      const chapter = (chapterIndex === 'quiz')
        ? 'quiz'
        : (chapterIndex + 1).toString().padStart(2, '0');
      return `/projects/${this.projectSlug}/${section}-${chapter}`;
    },
    scrollTo(titleSlug) {
      // scroll to top of tabs with offset
      window.scrollTo({ top: document.getElementById(titleSlug).offsetTop, behavior: 'smooth' });
    }
  },
}
</script>

<style lang="sass" scoped>
.inner-item:deep(.q-avatar)  
  width: 0.7em
  height: 0.7em

:deep(.q-item.q-router-link--active, .q-item--active)
  color: inherit
  background-color: $orange-2

.page-titles
  border-left: 1px solid $grey-4
  margin-left: 28px
</style>
