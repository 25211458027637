<template>
  <q-page-container class="content">
    <q-page padding>
      <template v-if="!loading">
        <div :class="['main-container', largeContainer ? 'inner-large-container' : 'inner-container']">
          <div class="items-container">
            <div :class="{ 'inner-container': largeContainer }"> <!-- force smaller container if need be -->
              <div :class="[($q.screen.gt.sm || !user) ? 'row' : '', 'items-center q-mt-md']" style="gap: 0.5rem; flex-wrap: nowrap;">
                <q-select
                  :model-value="currentSearch"
                  :placeholder="searchPlaceholder"
                  :options="filterOptions"
                  @filter="searchFilterFn"
                  @input-value="updateSearch"
                  @update:model-value="setSearch"
                  @keydown="(e) => {
                    if (e.key === 'Enter') setSearch();
                  }"
                  class="col-grow"
                  outlined
                  rounded
                  use-input
                  fill-input
                  hide-selected
                  input-debounce="0"
                >
                  <template v-slot:prepend>
                    <q-icon name="search" />
                  </template>
                  <template v-if="currentSearch" v-slot:append>
                    <q-icon name="cancel" @click.stop.prevent="clearSearch" class="cursor-pointer" />
                  </template>
                </q-select>
                <div :class="['row justify-center', $q.screen.lt.md ? 'q-my-md' : '']" style="gap: 0.5rem;">
                  <q-btn
                    v-if="user"
                    :icon="bookmarkedOnly ? 'bookmark' : 'bookmark_border'"
                    @click="bookmarkedOnly = !bookmarkedOnly"
                    color="primary"
                    size="lg"
                    round
                    outline
                  ></q-btn>
                  <q-btn
                    v-if="hasFilters"
                    :icon="showFiltersPanel ? 'close' : 'tune'"
                    @click="showFiltersPanel = !showFiltersPanel"
                    color="primary"
                    size="lg"
                    round
                    outline
                    style="position: relative; z-index: 200;"
                  >
                    <q-menu persistent class="filters-panel text-body1" anchor="bottom right" self="top right" :offset="[0, 10]">
                      <q-list style="min-width: 180px;">
                        <q-item class="text-h6">
                          <q-item-section>Search Filters</q-item-section>
                        </q-item>
                        <slot name="filters-panel"></slot>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </div>
      
              <slot name="special-filters"></slot>
            </div>

            <slot name="before-content"></slot>

            <template v-if="filteredItemSlugs.length > 0">
              <q-pagination
                v-if="paginate"
                v-model="page"
                @update:model-value="updateURL"
                :max="maxPages"
                :max-pages="maxDisplayedPages"
                :boundary-numbers="false"
                direction-links
                class="pagination"
              />
      
              <div :class="[$q.screen.gt.sm ? 'q-pa-lg' : 'q-pa-sm']">
                <div :class="['q-col-gutter-lg', itemGridClass]">
                  <div
                    v-for="slug in paginatedItems"
                    :key="slug"
                    :class="itemWrapperClass"
                  >
                    <slot :data="slug"></slot>
                  </div>
                </div>
              </div>
  
              <q-pagination
                v-if="paginate"
                v-model="page"
                @update:model-value="updateURL"
                :max="maxPages"
                :max-pages="maxDisplayedPages"
                :boundary-numbers="false"
                direction-links
                class="pagination"
              />
            </template>
            <template v-else>
              <div class="text-center" style="margin-top: 5rem;">
                <q-icon name="search_off" size="120px" color="grey-9"></q-icon>
                <div class="text-h5 q-mt-md">No results!</div>
              </div>
            </template>

          </div>
        </div>
      </template>
      <template v-else>
      <q-inner-loading :showing="loading">
        <q-spinner-hourglass
          color="primary"
          size="4em"
          class=""
        />
      </q-inner-loading>
    </template>
    </q-page>
  </q-page-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  // TYPE_LABELS, -> $options.TYPE_LABELS
  name: 'Content',
  props: {
    itemType: { type: String, required: true },
    items: { type: Array, required: true },
    reviews: { type: Object, required: true },
    tags: { type: Array, default: () => [] },
    itemGridClass: { type: String, default: '' },
    itemReadStateClass: { type: String, default: '' },
    itemWrapperClass: { type: String, default: '' },
    searchPlaceholder: { type: String, default: '' },
    largeContainer: { type: Boolean, default: false },
    hasFilters: { type: Boolean, default: false },
    paginate: { type: Boolean, default: false },
    itemsPerPage: { type: Number, default: 20 },
    maxDisplayedPages: { type: Number, default: 6 },
    fetchItemsFn: { type: Function, default: () => {} },
    extraFilterFn: { type: Function, default: (data) => data },
    queryComputeFn: { type: Function, default: () => [] },
    queryParseFn: { type: Function, default: (/*query*/) => {} },
  },
  data() {
    return {
      loading: true,
      showFiltersPanel: false,
      currentSearch: '',
      filterSearch: '',
      filterOptions: [],
      bookmarkedOnly: false,

      page: 0,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.patreon.user,
    }),
    bookmarkedItems() {
      if (!this.reviews || !this.user) return [];
      return this.items.filter((item) => {
        if (!(item.slug in this.reviews)) return false;
        return this.reviews[item.slug].bookmark?.includes(this.user.username) || false;
      }).map((p) => p.slug);
    },
    filteredItemSlugs() {
      let data = this.items;
      if (this.bookmarkedOnly) {
        data = data.filter((item) => this.bookmarkedItems.includes(item.slug));
      }

      if (this.filterSearch) {
        const search = this.filterSearch.toLowerCase();
        data = data.filter((item) => {
          return (
            item.name?.toLowerCase().includes(search) ||
            item.desc?.toLowerCase().includes(search) ||
            item.title?.toLowerCase().includes(search) ||
            item.abstract?.toLowerCase().includes(search) ||
            item.type?.includes(search) ||
            item.tags?.includes(search)
          );
        });
      }

      data = this.extraFilterFn(data);
      return data.map((p) => p.slug);
    },
    paginatedItems() {
      if (!this.paginate) return this.filteredItemSlugs;
      return this.filteredItemSlugs.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage);
    },
    maxPages() {
      return Math.ceil(this.filteredItemSlugs.length / this.itemsPerPage);
    },
  },
  watch: {
    '$route.query': {
      handler: function() {
        this.setFiltersFromQuery();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateSearch(v) {
      this.currentSearch = v;
      if (!v || v === '') {
        this.filterSearch = '';
      }
    },
    setSearch() {
      this.filterSearch = this.currentSearch;
      this.updateURL();
    },
    clearSearch() {
      this.currentSearch = '';
      this.filterSearch = '';
      this.updateURL();
    },
    searchFilterFn(val, update, abort) {
      if (!val || val.length < 1) {
        abort();
        return;
      }
      update(() => {
        const needle = val.toLocaleLowerCase();
        this.filterOptions = this.tags.filter(t => t.startsWith(needle));
      });
    },
    setFiltersFromQuery() {
      const { query } = this.$route;
      if ('q' in query) {
        this.currentSearch = query.q;
        this.filterSearch = query.q;
      } else {
        this.currentSearch = '';
        this.filterSearch = '';
      }
      this.queryParseFn(query);

      if (this.paginate) {
        if ('p' in query) {
          this.page = parseInt(query.p);
        } else {
          this.page = 1;
        }
      }
    },
    updateURL() {
      let url = this.$route.path;
      let query = [];
      if (this.filterSearch)
        query.push(`q=${this.filterSearch}`);
      if (this.paginate && this.page > 1)
        query.push(`p=${this.page}`);
      query = [ ...query, ...this.queryComputeFn() ];

      if (query.length > 0) {
        url += `?${query.join('&')}`;
      }

      this.$router.push(url);
    },
  },
  async created() {
    await this.fetchItemsFn();
    this.setFiltersFromQuery();
    this.loading = false;
  },
}
</script>

<style lang="sass" scoped>
.content:deep(.q-select__dropdown-icon)
  display: none !important

.content:deep(.q-option-group)
  margin: 12px 0

.content:deep(.q-checkbox--dense .q-checkbox__label)
  font-size: 16px !important

.content:deep(.q-col-gutter-lg)
  margin-left: -12px

.items-container
  padding: 0 48px
  body.screen--sm &,
  body.screen--xs &
    padding: 0

.pagination
  display: flex
  justify-content: center
  margin: 2rem 0


.grid-card
  width: 100%
.grid-card-small
  padding-left: 12px !important
</style>
