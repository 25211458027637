<template>
  <q-card flat bordered class="file-downloader">
    <q-card-section>
      <a :href="url" :download="name">
        <q-btn icon="download" outline color="grey-9"></q-btn>
      </a>
      <div class="text-body1 text-grey-9" v-html="label"></div>
      <q-avatar :icon="icon" color="primary" text-color="white"></q-avatar>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'FileDownloader',
  props: {
    name: { type: String, required: true },
    url: { type: String, required: true },
    desc: { type: String, default: '' },
  },
  computed: {
    label() {
      let l = `<b>${this.name}</b>`;
      if (this.desc) l += `: ${this.desc}`;
      return l;
    },
    icon() {
      const extension = this.url.split('.').pop().toLowerCase();
      return {
        jpg:  'image',
        jpeg: 'image',
        png:  'image',
        gif:  'image',
        mp3:  'audiotrack',
        wav:  'audiotrack',
        ogg:  'audiotrack',
        mp4:  'smart_display',
        txt:  'article',
        pdf:  'article',
        json: 'article',
        csv:  'article',
        xml:  'article',
        zip:  'folder_zip',
      }[extension];
    },
  },
}
</script>

<style lang="sass" scoped>
.file-downloader > div
  display: grid
  grid-template-columns: auto 1fr auto
  column-gap: 1rem
  align-items: center

.file-downloader button
  --btn-size: 40px
  width: var(--btn-size)
  height: var(--btn-size)
</style>
