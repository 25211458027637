import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';

const state = () => ({
  allProjects: [],
  projects: [],
  reviews: [],
  highlights: [],
  tags: [],
});

const getters = {
  isProjectAccessible: (state, getters, rootState) => (slug) => {
    const project = state.projects.find((p) => p.slug === slug);
    if (!project) return false;
    if (!project.requiresPatreon) return true;
    const { user } = rootState.patreon;
    if (user && user.role === 'admin') return true;
    return user && project.requiresPatreon <= user.rank;
  },
  isTutorialAccessible: (state, getters, rootState) => (slug, sectionIndex, chapterIndex) => {
    const project = state.projects.find((p) => p.slug === slug);
    const tutorial = chapterIndex === 'quiz'
      ? project.meta.sections[sectionIndex].quiz
      : project.meta.sections[sectionIndex].items[chapterIndex];
      const { user } = rootState.patreon;
    if (chapterIndex === 'quiz' && !user) return false;
    if (!tutorial.requiresPatreon) return true;
    if (user && user.role === 'admin') return true;
    return user && tutorial.requiresPatreon <= user.rank;
  },
  getProjectBySlug: (state) => (slug) => {
    return state.projects.find((p) => p.slug === slug);
  },
  getProjectsByState: (state) => (projectState) => {
    return state.projects
      .filter((p) => p.state === projectState)
      .map((p) => p.slug);
  },
};

const mutations = {
  setProjects(state, { allProjects }) {
    const projects = allProjects.filter((p) => (!('draft' in p) || p.draft === false));

    // get tags + prepare tutorial parts if need be
    const tags = [];
    for (const p of projects) {
      p.tags.push(p.type.toLowerCase());
      p.tags.push(...p.tools.map((t) => t.toLowerCase()));
      tags.push(...p.tags);

      if (p.type === 'tutorial')
        p.parts = {};
    }

    state.allProjects = allProjects;
    state.projects = projects;
    state.tags = [ ...new Set(tags) ];
  },
  setProjectData(state, { slug, projectData }) {
    const projects = cloneDeep(state.projects);
    const idx = state.projects.findIndex((p) => p.slug === slug);
    projects[idx] = { ...projects[idx], ...projectData };
    state.projects = projects;
  },
  setProjectParameter(state, { slug, key, value }) {
    const projects = cloneDeep(state.projects);
    let idx = projects.findIndex((p) => p.slug === slug);
    projects[idx] = { ...projects[idx], [key]: value };
    state.projects = projects;

    const allProjects = cloneDeep(state.allProjects);
    idx = allProjects.findIndex((p) => p.slug === slug);
    allProjects[idx] = { ...allProjects[idx], [key]: value };
    state.allProjects = allProjects;
  },
  setProjectTutorialPartData(state, { slug, part, partData }) {
    const projects = cloneDeep(state.projects);
    const idx = state.projects.findIndex((p) => p.slug === slug);
    projects[idx].parts[part] = partData;
    state.projects = projects;
  },
  setReviews(state, { reviews }) {
    state.reviews = reviews;
  },
  setHighlights(state, { highlights }) {
    state.highlights = highlights;
  },
  setHighlight(state, { index, value }) {
    const highlights = [...state.highlights];
    highlights[index] = value;
    state.highlights = highlights;
  },
};

const actions = {
  async getProjects({ commit }) {
    const { data } = await axios.get('/projects');
    commit('setProjects', { allProjects: data.projects });
    commit('setReviews', { reviews: data.reviews });
    return data;
  },
  async getHighlights({ commit }) {
    const { data: highlights } = await axios.get('/projects/highlights');
    commit('setHighlights', { highlights });
    return highlights;
  },
  async getProjectData({ commit }, { slug }) {
    const { data: projectData } = await axios.get(`/projects/${slug}`);
    commit('setProjectData', { slug, projectData });
    return projectData;
  },
  async getProjectTutorialPartData({ state, dispatch, commit }, { slug, part }) {
    const projectIndex = state.projects.findIndex((p) => p.slug === slug);
    if (!('meta' in state.projects[projectIndex]))
      await dispatch('getProjectData', { slug });

    const partSectionIndex = parseInt(part.substr(0, 2)) - 1;
    const partChapterIndex = parseInt(part.substr(3, 2)) - 1;
    const section = state.projects[projectIndex].meta.sections[partSectionIndex];

    const { data } = await axios.get(`/projects/${slug}/tutorial-part/${part}`);
    const partData = { section, meta: section.items[partChapterIndex], content: data };
    commit('setProjectTutorialPartData', { slug, part, partData });
    return partData;
  },
  async getProjectTutorialQuestion(_, { slug, sectionIndex, questionIndex }) {
    const question = `${sectionIndex.toString().padStart(2, '0')}-Q${questionIndex}`;
    const { data: content } = await axios.get(`/projects/${slug}/tutorial-quiz/${question}`);
    return content;
  },
  async toggleReview({ commit }, { slug, name }) {
    const { data: reviews } = await axios.post(`/projects/${slug}/review`, { name });
    commit('setReviews', { reviews });
    return reviews;
  },
  async updateProject({ commit }, { slug, key, value }) {
    await axios.post(`/projects/${slug}/update`, { key, value });
    commit('setProjectParameter', { slug, key, value });
  },
  async updateHighlight({ commit }, { index, value }) {
    await axios.post(`/projects/highlight/${index}`, { value });
    commit('setHighlight', { index, value });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
