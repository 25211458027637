<template>
  <q-header class="bg-white text-grey-10 relative">
    <q-toolbar class="row justify-center">
      <Navigation responsive></Navigation>
    </q-toolbar>
  </q-header>
</template>

<script>
import Navigation from './Navigation.vue';

export default {
  name: 'Navbar',
  components: { Navigation },
}
</script>
