<template>
  <q-chip
    square
    :color="data.color"
    :icon="data.icon"
    :class="`non-selectable ${elemClass}`"
    :style="style"
  >
    {{ data.name }}
  </q-chip>
</template>

<script>
import { TYPE_COLORS, TYPE_ICONS, TYPE_LABELS } from '@/helpers/type';

export default {
  name: 'ProjectTagType',
  props: {
    type: { type: String, required: true },
    elemClass: { type: String, default: '' },
    style: { type: Object, default: () => {} },
  },
  computed: {
    data() {
      let color = 'transparent';
      if (this.type in TYPE_COLORS) {
        color = TYPE_COLORS[this.type];
        if (!(color.match(/.*-\d/)))
          color += '-3';
      }
      const icon = (this.type in TYPE_ICONS) ? TYPE_ICONS[this.type] : null;
      const name = (this.type in TYPE_LABELS) ? TYPE_LABELS[this.type] : '';
      return { color, icon, name };
    },
  }
}
</script>
