<template>
  <template v-if="responsive && $q.screen.lt.md">
    <q-btn push icon="menu" color="primary" @click="showMenu = true"></q-btn>
    <q-dialog
      v-model="showMenu"
      style="width: 66vw;"
    >
      <q-card>
        <q-card-section class="column q-pa-xl">
          <q-btn
            v-for="menu in menus"
            :key="menu"
            flat
            :color="$route.name === menu ? 'primary' : 'grey-9'"
            :to="{ name: menu, query: menu?.query || {} }"
            :class="['menu-btn', 'q-my-md', $route.name === menu ? 'active' : '', 'text-no-decoration']"
            size="lg"
            v-close-popup
          >
            {{ menu }}
          </q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </template>
  <div v-else class="row">
    <q-btn
      v-for="menu in menus"
      :key="menu"
      flat
      :color="$route.name === menu ? 'primary' : 'grey-9'"
      :to="{ name: menu, query: menu?.query || {} }"
      :class="['menu-btn', $route.name === menu ? 'active' : '', 'text-no-decoration']"
      size="15px"
    >
      {{ menu }}
    </q-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Navigation',
  props: {
    responsive: { type: Boolean, default: false },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects.projects,
      posts: (state) => state.posts.posts,
    }),
    menus() {
      return ['home', 'library', 'blog', 'about'];
    },
  },
  async created() {
    if (this.projects.length === 0)
      await this.$store.dispatch('projects/getProjects');
    if (this.posts.length === 0)
      await this.$store.dispatch('posts/getPosts');
  },
}
</script>

<style lang=sass scoped>
.menu-btn
  border-radius: 0
  margin-left: 3px
  margin-right: 3px

.menu-btn.active
  border-bottom-color: $primary
</style>
