/* -------- PRISM HIGHLIGHTING ---------------- */
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';

import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-glsl';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-xml-doc';

const PRISM_SELECTOR = 'code[class*="language-"], [class*="language-"] code, code[class*="lang-"], [class*="lang-"] code';
/* ----- (end of PRISM HIGHLIGHTING) ---------- */

const BASE_URL = process.env.BASE_URL;

export const parseHtmlSections = (htmlString, titleLevel = 'h5') => {
    const sectionRegex = new RegExp(`<${titleLevel}>([^<]+)</${titleLevel}>`, 'g');

    const sections = [];
    let cursor = 0;
    let section = { title: '', content: '' };
    for (const match of [...htmlString.matchAll(sectionRegex)]) {
        section.content = htmlString.substr(cursor, match.index - cursor).trim();
        if (section.content.length > 0)
            sections.push(section);

        section = { title: match[1], content: '' };
        cursor = match.index + match[0].length;
    }
    section.content = htmlString.substr(cursor).trim();
    sections.push(section);

    return sections;
};

export const parseAndHighlightWordsFill = (htmlString, lang) => {
    const inputRegex = new RegExp(`<input data-size="([^"]+)" data-answer="([^"]+)"( data-extra="([^"]+)")? ?/>`, 'g');
    const grammar = Prism.languages[lang];
    
    const inputs = [];
    const lines = htmlString.split('\n');
    let html = '', input = null, match = null;
    for (const line of lines) {
        let processedLine = line;
        match = inputRegex.exec(processedLine);
        if (match) {
            input = { size: match[1], answer: match[2] };
            if (match[3])
                input.extra = match[4];
            inputs.push(input);
            
            processedLine = processedLine.replace(match[0], 'faketag');
            // special case:
            //  . closing-tag
            if (input.extra === 'closing-tag') {
                processedLine = processedLine.replace(`</${input.answer}>`, '</faketag>');
            }

            inputRegex.lastIndex = 0; // reset for next time
        }
        const tokens = Prism.tokenize(processedLine, grammar);
        html += `${Prism.Token.stringify(Prism.util.encode(tokens), lang)}\n`;
    }
    html = html.trim();
    return { html, inputs };
};

export const getRelativeImg = (src) => {
    if (src.startsWith('http')) return src;
    return `${BASE_URL}${src.replace(/^\/+/g, '')}`;
};

export const highlightCode = (container = null) => {
    if (container === null) container = document.querySelector('.main-container');
    if (!container) return;
    const scripts = container.querySelectorAll(PRISM_SELECTOR);
    if (!scripts) return;
    for (const script of scripts)
        Prism.highlightElement(script);
};

export const createRouteLinks = (vueComponent, container = null) => {
    if (container === null) container = document.querySelector('.main-container');
    if (!container) return;
    const htmlLinks = container.querySelectorAll('span[data-route]');
    if (!htmlLinks) return;
    for (const htmlLink of htmlLinks) {
        const a = document.createElement('a');
        a.innerText = htmlLink.innerText;
        a.style.textDecoration = 'underline';
        a.style.cursor = 'pointer';
        a.onclick = () => {
            vueComponent.$router.push(htmlLink.getAttribute('data-route'));
        }
        htmlLink.replaceWith(a);
    }
};

export const fitContainerWithSidePanel = (offset = 16) => {
    const sidePanel = document.getElementById('side-panel');
    const container = document.querySelector('.inner-container');
    if (container && sidePanel) {
      const { width } = sidePanel.getBoundingClientRect();
      const { left } = container.getBoundingClientRect();
      const diff = left - width;
      if (diff < 0)
        container.style.paddingLeft = `${-diff + offset}px`;
    }
};

export const blockPageScroll = (on) => {
    if (on) {
        document.documentElement.setAttribute('data-noscroll', true);
    } else {
        document.documentElement.removeAttribute('data-noscroll');
    }
}
