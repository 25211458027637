export const STATE_COLORS = {
    'todo': 'info',
    'wip': 'orange',
    'done': 'green',
};

export const STATE_ICONS = {
    'todo': 'lightbulb',
    'wip': 'settings',
    'done': 'done',
};

export const STATE_LABELS = {
    'todo': 'Early idea',
    'wip': 'In progress',
    'done': 'Finished',
};
