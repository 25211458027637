<template>
  <div :class="['music-playlist shadow-8 rounded-borders', showPlaylist ? 'in-playlist' : '']">
    <div class="img-wrapper">
      <q-btn round class="playlist-toggle" :icon="showPlaylist ? 'close' : 'queue_music'"
        @click="showPlaylist = !showPlaylist"></q-btn>
      <div class="img-container">
        <img class="shadow-7 rounded-borders" :src="$options.getRelativeImg(data[currentTrack].picture || '/music-cover-placeholder.png')" />
        <div class="playlist-container rounded-borders">
          <q-list dark separator class="text-body1">
            <q-item
              v-for="(track, index) in data"
              :key="index"
              @click="playTrack(index)"
              clickable
              v-ripple
              :active="index === currentTrack"
            >
              <q-item-section>{{ index + 1 }} - {{ track.name }}</q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
    <div class="controls">
      <div class="text-center text-grey-9">
        <div class="text-h5">{{ data[currentTrack].name }}</div>
        <div class="text-body1">{{ data[currentTrack].credits }}</div>
      </div>
      <div class="buttons q-mt-md q-mb-sm">
        <q-btn
          icon="skip_previous"
          @click="playPrevious"
          :disable="currentTrack === 0"
          round
          outline
          color="primary"
          size="18px"
        ></q-btn>
        <q-btn
          :icon="isPlaying ? 'pause' : 'play_arrow'"
          @click="togglePlay"
          round
          color="primary"
          size="24px"
        ></q-btn>
        <q-btn
          icon="skip_next"
          @click="playNext"
          :disable="currentTrack === data.length - 1 && !loop"
          round
          outline
          color="primary"
          size="18px"
        ></q-btn>
      </div>
      <div class="track">
        <q-btn-group rounded>
          <q-btn flat round icon="redo" size="14px" :color="autoplay ? 'primary' : 'grey-7'" @click="autoplay = !autoplay">
            <q-tooltip>Autoplay</q-tooltip>
          </q-btn>
          <q-btn flat round icon="shuffle" size="14px" :color="shuffle ? 'primary' : 'grey-7'" @click="shuffle = !shuffle">
            <q-tooltip>Shuffle</q-tooltip>
          </q-btn>
          <q-btn flat round icon="loop" size="14px" :color="loop ? 'primary' : 'grey-7'" @click="loop = !loop">
            <q-tooltip>Loop</q-tooltip>
          </q-btn>
        </q-btn-group>
        <q-linear-progress
          ref="track"
          :value="trackPlayedRatio"
          size="8px"
          instant-feedback
          rounded
          @click="seek"
        ></q-linear-progress>
        <div>{{ trackPlayedLabel }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getRelativeImg } from '@/helpers/html.js';
import { delay } from '@/helpers/misc.js';

export default {
  getRelativeImg,
  name: 'MusicPlaylist',
  props: {
    data: { type: Array, required: true },
  },
  data() {
    return {
      routePath: null,

      showPlaylist: false,
      currentTrack: 0,
      trackDuration: 0,
      trackCursor: 0,
      trackPlayedRatio: 0,
      trackPlayedLabel: '00:00',
      isPlaying: false,
      autoplay: true,
      shuffle: false,
      loop: true,
      availableTrackIndexes: [],
    };
  },
  computed: {
    ...mapState({
      crossPageMusic: (state) => state.global.crossPageMusic,
    }),
  },
  methods: {
    playPrevious() {
      this.playTrack(this.currentTrack - 1);
    },
    playNext() {
      this.playTrack(this.currentTrack + 1);
    },
    async playTrack(index, autoplay = true, currentTime = 0) {
      await delay(150);

      if (this.audio)
        this.audio.pause();

      this.currentTrack = index;
      this.setTrackData(currentTime);

      const $this = this;
      await new Promise((resolve) => {
				const audioFile = $this.data[$this.currentTrack].mp3;
				$this.audio = new Audio(audioFile);
        $this.audio.currentTime = currentTime;
				resolve();
			});

			this.audio.onloadedmetadata = () => {
        $this.trackDuration = $this.audio.duration;
      };
      if (this.crossPageMusic !== null) {
        this.audio.onloadeddata = () => {
          $this.$store.commit('global/setCrossPageMusic', null);
        };
      }
			this.audio.onloadedmetadata = () => {
        $this.trackDuration = $this.audio.duration;
      };
			this.audio.onended = this.onEnded;

      if (this.checkTrackCursorInterval)
        clearInterval(this.checkTrackCursorInterval);
      if (autoplay) {
        this.checkTrackCursor();

        this.isPlaying = true;
        this.audio.play();
      } else {
        this.isPlaying = false;
      }
    },
    togglePlay() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        // if cross-page player is playing, close it and give
        // back this playlist the control
        this.$store.commit('global/setCrossPageMusic', null);

        this.audio.play();
        this.checkTrackCursor();
      }
      else {
        this.audio.pause();
        if (this.checkTrackCursorInterval)
          clearInterval(this.checkTrackCursorInterval);
      }
    },
    seek(event) {
      if (!this.$refs.track) return 0;
      const el = this.$refs.track.$el;
      const r = (event.pageX * 1.0 - el.getBoundingClientRect().left) / el.clientWidth;
      const t = Math.round(r * this.trackDuration);
      this.audio.currentTime = t;
      this.setTrackData(t);
    },
    checkTrackCursor() {
      const $this = this;
      this.checkTrackCursorInterval = window.setInterval(() => {
        const t = $this.audio.currentTime;
        $this.currentTime = t;
        $this.setTrackData(t);
      }, 1000);
    },
    setTrackData(currentTime) {
      this.trackPlayedRatio = currentTime / this.trackDuration;
      const min = Math.floor(currentTime / 60);
      const sec = Math.round(currentTime - min * 60);
      this.trackPlayedLabel = `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
    },
    onEnded() {
      const isLastSong = this.currentTrack === this.data.length - 1;
      if (isLastSong && !this.loop) {
        this.isPlaying = false;
        return;
      }

      if (this.autoplay) {
        this.availableTrackIndexes.splice(this.availableTrackIndexes.findIndex((v) => v === this.currentTrack), 1);
        if (this.availableTrackIndexes.length === 0) {
          for (let i = 0; i < this.data.length; i++) {
            if (i != this.currentTrack)
              this.availableTrackIndexes.push(i);
          }
          this.availableTrackIndexes.push(this.currentTrack);
        }
        if (this.shuffle) {
          const i = Math.floor(Math.random() * this.availableTrackIndexes.length);
          this.playTrack(this.availableTrackIndexes[i]);
        }
        else {
          if (isLastSong)
            this.playTrack(0, true);
          else
            this.playNext();
        }
      }
      else this.isPlaying = false;
    },
  },
  created() {
    for (let i = 0; i < this.data.length; i++)
      this.availableTrackIndexes.push(i);

    this.routePath = this.$route.path;
  },
  async mounted() {
    // if cross-page player is playing:
    // . check if it matches the current playlist; in that case
    //   reintegrate the audio inside the playlist
    // . close it and give the control back to the playlist
    const crossPlayerMatches = this.crossPageMusic && (this.crossPageMusic.ref === this.routePath);
    if (crossPlayerMatches) {
      this.playTrack(this.crossPageMusic.offset, true, this.crossPageMusic.currentAudio.currentTime);
    } else {
      this.playTrack(0, false);
    }
  },
  beforeUnmount() {
    if (this.checkTrackCursorInterval)
      clearInterval(this.checkTrackCursorInterval);

    // if still playing, transform into cross-page player
    if (this.isPlaying) {
      this.$store.commit('global/setCrossPageMusic', {
        data: this.data.slice(this.currentTrack).map((item) => ({
          picture: item.picture,
          mp3: item.mp3
        })),
        ref: this.routePath,
        currentAudio: this.audio,
        offset: this.currentTrack,
      });
    }
  }
}
</script>

<style lang="sass" scoped>
.music-playlist
  --padding: 12px
  padding: var(--padding) var(--padding) calc(var(--padding) * 2) var(--padding)
  max-width: 600px
  margin: 5rem auto 1rem auto
  display: grid
  grid-template-rows: 65% 35%

// PICTURE
.music-playlist .img-wrapper
  width: 80%
  max-width: 500px
  margin: 0 auto
  position: relative
  transform: translateY(-15%)

.music-playlist .img-container
  position: relative

.music-playlist img
  margin: 0 !important
  transition: opacity 0.35s ease
  opacity: 1

.music-playlist.in-playlist img
  max-width: 128px
  opacity: 0

// PLAYLIST
.music-playlist .playlist-container
  background-color: #121212
  color: $grey-2
  overflow-y: auto
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  opacity: 0
  transition: opacity 0.35s ease
.music-playlist.in-playlist .playlist-container
  opacity: 1

// TOGGLE
.music-playlist .playlist-toggle
  background-color: white
  position: absolute
  z-index: 20
  top: 8px
  right: 8px

// CONTROLS
.music-playlist .controls
  align-self: center

.music-playlist .controls .buttons,
.music-playlist .controls .track
  display: flex
  justify-content: center
  align-items: center
  column-gap: 1rem

.music-playlist .controls .track
  margin: 0 auto
  padding-bottom: var(--padding)
  width: 80%
  max-width: 500px
  font-size: 1rem
</style>
