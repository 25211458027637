<template>
  <svg viewBox="0 0 16 16" :width="size" :height="size" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M13.6313333,13.6346667 L11.262,13.6346667 L11.262,9.922 C11.262,9.03666667 11.244,7.89733333 10.0273333,7.89733333 C8.792,7.89733333 8.60333333,8.86066667 8.60333333,9.85666667 L8.60333333,13.6346667 L6.234,13.6346667 L6.234,6 L8.51,6 L8.51,7.04066667 L8.54066667,7.04066667 C8.85866667,6.44066667 9.632,5.80733333 10.7873333,5.80733333 C13.188,5.80733333 13.632,7.38733333 13.632,9.444 L13.632,13.6346667 L13.6313333,13.6346667 Z M3.558,4.95533333 C2.79533333,4.95533333 2.18266667,4.338 2.18266667,3.57866667 C2.18266667,2.82 2.796,2.20333333 3.558,2.20333333 C4.318,2.20333333 4.934,2.82 4.934,3.57866667 C4.934,4.338 4.31733333,4.95533333 3.558,4.95533333 Z M4.746,13.6346667 L2.37,13.6346667 L2.37,6 L4.746,6 L4.746,13.6346667 Z M14.8166667,0 L1.18066667,0 C0.528,0 0,0.516 0,1.15266667 L0,14.8473333 C0,15.4846667 0.528,16 1.18066667,16 L14.8146667,16 C15.4666667,16 16,15.4846667 16,14.8473333 L16,1.15266667 C16,0.516 15.4666667,0 14.8146667,0 L14.8166667,0 Z"></path>
  </svg>
</template>

<script>
export default {
  name: 'IconLinkedin',
  props: {
    color: { type: String, default: '#ffffff' },
    size: { type: Number, default: 24 },
  }
}
</script>
