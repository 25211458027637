import cloneDeep from 'lodash.clonedeep';

const state = () => ({
  pageIsScrollable: false,
  showLoginDialog: false,
  crossPageMusic: null,
});

const mutations = {
  showLoginDialog(state, { show }) {
    state.showLoginDialog = show;
  },
  setPageScroll(state, { scrollable }) {
    state.pageIsScrollable = scrollable;
  },
  setCrossPageMusic(state, params) {
    state.crossPageMusic = params;
  },
  updateCrossPageMusicAudio(state, { audio }) {
    const crossPageMusic = cloneDeep(state.crossPageMusic);
    crossPageMusic.currentAudio = audio;
    state.crossPageMusic = crossPageMusic;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
