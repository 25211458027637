<template>
  <!-- must be used inside <q-page></q-page> -->
  <q-card :class="`side-floating-panel fixed q-pa-lg`" :style="computedStyle">
    <slot></slot>
  </q-card>
</template>

<script>
export default {
  name: 'SideFloatingPanel',
  props: {
    style: { type: Object, default: () => {} },
  },
  computed: {
    computedStyle() {
      return {
        left: '-4px',
        zIndex: '99',
        ...this.style,
      };
    },
  }
}
</script>

<style lang="sass" scoped>
.side-floating-panel
  transition: transform 0.5s ease
  overflow-x: visible
</style>
