import { createApp } from 'vue';
import router from './router';
import store from './store';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';

/* eslint-disable-next-line no-unused-vars */
import axios from './axios';

import 'animate.css';

import App from './App.vue';
import Notifications from '@/components/notifications/Notifications.vue';

const app = createApp(App);

app.config.globalProperties.$lib = {};

app.component('Notifications', Notifications)
    .use(Quasar, quasarUserOptions)
    .use(store)
    .use(router)
    .mount('#app');
